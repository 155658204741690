import { useState } from 'react'
import { DateTime } from 'luxon'
import { SortByInput, SortOrder, useMachinePartsHistoryQuery } from '@/buyers/_gen/gql'
import RequestPartButton from '@/buyers/components/RequestPartButton'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import { Table, Td, Th, Tr } from '@/gf/components/next/Table'
import SortByHeaderButton from '@/gf/components/SortByHeaderButton'
import Time from '@/gf/modules/Time'
import PaginationC from '@/gf/components/Pagination'
import usePage from '@/gf/hooks/usePage'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { SimpleTooltip } from '@/gf/components/next/Tooltip'
import Link from '@/gf/components/Link'

const Parts = ({ orgMachineId }: { orgMachineId: string }) => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const [page, setPage] = usePage()
  const [sortBy, setSortBy] = useState<SortByInput>({
    field: 'last_requested_at',
    order: SortOrder.Desc,
  })

  const { data } = useMachinePartsHistoryQuery({
    client,
    variables: {
      orgMachineId,
      page,
      orgId,
      sortBy,
    },
  })

  return (
    <div className="space-y-4">
      <p className="text-sm">
        Below is a list of parts associated with this machine that you have previously ordered.
      </p>
      <Table className="text-sm">
        <thead>
          <Tr>
            <Th>Part Number</Th>
            <Th>
              <SortByHeaderButton
                display="Part Name"
                field="part_description"
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
            </Th>
            <Th>
              <SortByHeaderButton
                display="Orders"
                field="num_of_orders"
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
            </Th>
            <Th>
              <SortByHeaderButton
                display="Last Request"
                field="last_requested_at"
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
            </Th>
            <Th>
              <SortByHeaderButton
                display="Frequency"
                field="request_frequency"
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
            </Th>
            <Th>
              <SortByHeaderButton
                display="Predicted Need"
                field="needed_by"
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
            </Th>
            <Th className="w-32" />
          </Tr>
        </thead>
        <tbody>
          {data ? (
            data.periodicPartRequests.pagination.totalResults === 0 ? (
              <Tr>
                <Td colSpan={7}>No orders have been created for this machine</Td>
              </Tr>
            ) : (
              data.periodicPartRequests.entries.map((p) => (
                <Tr key={p.id}>
                  <Td>{p.partNumber}</Td>
                  <Td>{p.partDescription} </Td>
                  <Td>
                    {p.neededBy ? (
                      <Link.T
                        to={`/reporting/consumption?selected=${p.id}&search=${p.partNumber}`}
                        target="_blank"
                      >
                        {p.numOfOrders} orders
                      </Link.T>
                    ) : (
                      `${p.numOfOrders} orders`
                    )}
                  </Td>
                  <Td>{p.lastRequestedAt.toLocaleString(DateTime.DATE_SHORT)} </Td>
                  <Td>
                    {p.requestFrequencySec ? (
                      `${p.requestQty} every ${Time.secondsToString2(p.requestFrequencySec)}`
                    ) : (
                      <SimpleTooltip
                        theme="light"
                        placement="top"
                        className="max-w-[19rem] text-center"
                        text="Because this part has only been ordered once, we are unable to determine the frequency and predicted need."
                      >
                        <span className="flex gap-x-2 items-center">
                          No frequency <InformationCircleIcon className="h-4 w-4 text-blue-600" />
                        </span>
                      </SimpleTooltip>
                    )}
                  </Td>
                  <Td>
                    {p.neededBy ? (
                      <>
                        {p.neededBy.toRelative()}
                        <span className="text-xs text-gray-600 block">
                          {p.neededBy.toLocaleString(DateTime.DATE_SHORT)}
                        </span>
                      </>
                    ) : (
                      'No current prediction'
                    )}
                  </Td>
                  <Td>
                    <RequestPartButton
                      partNumber={p.partNumber}
                      partDescription={p.partDescription}
                      requestQty={p.requestQty}
                      orgMachineId={orgMachineId}
                    />
                  </Td>
                </Tr>
              ))
            )
          ) : (
            <Tr>
              <Td colSpan={7}>Loading...</Td>
            </Tr>
          )}
        </tbody>
      </Table>
      <PaginationC
        page={page}
        pagination={data?.periodicPartRequests.pagination}
        updatePage={setPage}
      />
    </div>
  )
}

export default Parts
