import {
  AddressInput,
  PreferredSetting,
  useCreateVendorMutation,
  useVendorModalQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Field from '@/gf/components/next/forms/Field'
import FieldError from '@/gf/components/next/forms/FieldError'
import PhoneInput from '@/gf/components/next/forms/PhoneInput'
import TextInput from '@/gf/components/next/forms/TextInput'
import RedAlert from '@/gf/components/RedAlert'
import { ApolloError } from '@apollo/client'
import { PlusIcon, XIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import BrandsSelector from './BrandsSelector'
import GoogleBusinessTypeahead from '@/dealers/components/AddCustomerModal/GoogleBusinessTypeahead'
import { FocusTrap } from '@headlessui/react'
import Address from '../modules/Address'
import { Maybe } from '@/types'
import InlineAddress from '@/dealers/components/InlineAddress'

type FormValues = {
  name: string
  accountNumbers: { value: string }[]
  contactName: string
  contactEmail: string
  contactPhone: string
  delivery: boolean
  fleetioId: number | null
  preferenceSettings: {
    value: PreferredSetting
    brandIds: string[]
  }
  address: Maybe<AddressInput>
}

const AddVendorModal = ({
  onClose,
  orgMachineId,
  onVendorCreated,
  showCancelButton = false,
  transparentFooter = false,
  onCancel,
  draftMode,
}: {
  onClose: () => void
  orgMachineId?: string | null
  onVendorCreated: (id: string | null | undefined, name: string) => void
  showCancelButton?: boolean
  transparentFooter?: boolean
  onCancel?: () => void
  draftMode?: boolean
}) => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const [createVendorMutation] = useCreateVendorMutation({ client })

  const [saving, setSaving] = useState(false)
  const [createVendorErrors, setCreateVendorErrors] = useState<string[]>([])
  const { org } = useVendorModalQuery({ variables: { orgId }, client }).data || {}
  const showFleetioId = org?.orgApps.some((oa) => oa.appId === 'fleetio')

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('Account Name is required'),
        accountNumbers: draftMode
          ? Yup.array()
          : Yup.array()
              .of(
                Yup.object().shape({
                  value: Yup.string().required('Account Number is required'),
                })
              )
              .min(1, 'Account Number is required')
              .ensure(),
        contactName: draftMode
          ? Yup.string().nullable()
          : Yup.string().required('Contact Name is required'),
        contactEmail: draftMode
          ? Yup.string().nullable()
          : Yup.string().trim().required('Email is required').email('Invalid email format'),
        contactPhone: Yup.string(),
        delivery: Yup.boolean(),
        preferenceSettings: Yup.object().shape({
          value: Yup.string().oneOf([
            PreferredSetting.None,
            PreferredSetting.All,
            PreferredSetting.Brands,
          ]),
          brandIds: Yup.array(Yup.string()),
        }),
        ...(showFleetioId
          ? {
              fleetioId: Yup.number()
                .nullable()
                .required('Fleetio Vendor ID is required')
                .transform((v) => (Number.isNaN(v) ? null : v)),
            }
          : {}),
        address: Yup.object().nullable(),
      }),
    [showFleetioId, draftMode]
  )

  const form = useForm<FormValues>({
    defaultValues: {
      name: '',
      accountNumbers: [{ value: '' }],
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      delivery: false,
      fleetioId: null,
      preferenceSettings: {
        value: PreferredSetting.Brands,
        brandIds: [],
      },
    },
    shouldFocusError: true,
    resolver: yupResolver(validationSchema),
  })

  const accountNumbers = useFieldArray({ control: form.control, name: 'accountNumbers' })

  const onSubmit = async (formValues: FormValues) => {
    setSaving(true)
    setCreateVendorErrors([])

    await createVendorMutation({
      variables: {
        name: formValues.name,
        accountNumbers: draftMode ? [] : formValues.accountNumbers.map(({ value }) => value),
        contacts: draftMode
          ? []
          : [
              {
                name: formValues.contactName,
                email: formValues.contactEmail,
                phone: formValues.contactPhone,
              },
            ],
        delivery: formValues.delivery,
        offline: false,
        selectedBuyerId: null,
        brandIds: formValues.preferenceSettings.brandIds,
        orgMachineId: orgMachineId || null,
        fleetioId: formValues.fleetioId,
        dealerLocationId: null,
        draft: draftMode ?? false,
        address: formValues.address,
      },
    })
      .then(({ data }) => {
        onVendorCreated(data?.createVendor, formValues.name)
        onClose()
      })
      .catch(
        (
          err: Omit<ApolloError, 'graphQLErrors'> & {
            graphQLErrors: (ApolloError['graphQLErrors'][number] & {
              fields: { value: string }[]
            })[]
          }
        ) => {
          const errors = err.graphQLErrors.flatMap((e) => e.fields.map((f) => f.value))
          setCreateVendorErrors(errors)
        }
      )
      .finally(() => {
        setSaving(false)
      })
  }

  return (
    <FocusTrap>
      <div className="flex flex-shrink-0 justify-end px-3 pt-3">
        <CloseModalButton onClick={onClose} />
      </div>

      <div className="overflow-y-auto flex-grow px-6 pb-6 text-gray-900 flex flex-col gap-y-6">
        <h3 className="text-2xl font-medium">Add Your Vendor&apos;s Information</h3>

        {createVendorErrors.length > 0 && (
          <RedAlert>
            {createVendorErrors.map((error) => (
              <div key={error}>{error}</div>
            ))}
          </RedAlert>
        )}

        <form
          className="flex flex-col gap-y-6"
          id="vendor-form"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="space-y-4">
            <Controller
              control={form.control}
              name="name"
              render={({ field, fieldState }) => (
                <Field label="Vendor Name" error={fieldState.error?.message}>
                  <GoogleBusinessTypeahead
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value)
                      if (value === '' || value.length < 3) {
                        console.log('reset address')
                        form.setValue('address', null)
                      }
                    }}
                    autoFocus
                    onDetailsFound={(details) => {
                      if (details.address) {
                        form.setValue('address', Address.toGraphQlAddressInput(details.address), {
                          shouldValidate: true,
                        })
                      }

                      if (details.name) {
                        form.setValue('name', details.name, { shouldValidate: true })
                      }

                      if (!draftMode && details.phoneNumber) {
                        form.setValue('contactPhone', details.phoneNumber, {
                          shouldValidate: true,
                        })
                      }
                    }}
                  />
                </Field>
              )}
            />

            <Controller
              control={form.control}
              name="address"
              render={({ field }) =>
                field.value ? (
                  <Field label="Vendor Address" className="flex flex-col gap-y-1 items-start">
                    <InlineAddress
                      city={field.value?.city}
                      lineOne={field.value?.lineOne}
                      postalCode={field.value?.postalCode}
                      state={field.value.state}
                    />
                    <Action.T onClick={() => form.setValue('address', null)} className="text-sm">
                      Clear Address
                    </Action.T>
                  </Field>
                ) : (
                  <></>
                )
              }
            />
          </div>

          <Controller
            control={form.control}
            name="preferenceSettings.brandIds"
            render={({ field, fieldState }) => (
              <Field
                label="Brands (optional)"
                help="What brands do you order from this vendor?"
                error={fieldState.error?.message}
              >
                <BrandsSelector value={field.value} onChange={field.onChange} />
              </Field>
            )}
          />

          {!draftMode && (
            <Field label="Account Number">
              {accountNumbers.fields?.map((n, i) => (
                <>
                  <div className="flex gap-x-2 pt-1">
                    <TextInput key={n.id} {...form.register(`accountNumbers.${i}.value`)} />
                    {i > 0 && (
                      <button type="button" onClick={() => accountNumbers.remove(i)}>
                        <XIcon className="text-gray-700 h-5 w-5" />
                      </button>
                    )}
                  </div>

                  <FieldError error={form.formState.errors.accountNumbers?.[i]?.value?.message} />
                </>
              ))}
              <Action.T
                onClick={() => accountNumbers.append({ value: '' })}
                className="text-sm no-underline flex items-center gap-x-1.5 pt-1"
              >
                <PlusIcon className="inline-block h-4 w-4" /> Add additional account number
              </Action.T>
            </Field>
          )}

          {!draftMode && (
            <div className="flex flex-col gap-y-2">
              <Field
                label="Your Account Representative"
                error={form.formState.errors.contactName?.message}
              >
                <TextInput {...form.register('contactName')} placeholder="Enter name" />
              </Field>

              <Controller
                control={form.control}
                name="contactPhone"
                render={({ field, fieldState }) => (
                  <Field error={fieldState.error?.message}>
                    <PhoneInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder="Phone number"
                    />
                  </Field>
                )}
              />

              <Field error={form.formState.errors.contactEmail?.message}>
                <TextInput {...form.register('contactEmail')} placeholder="Email address" />
              </Field>
            </div>
          )}

          {!draftMode && showFleetioId && (
            <Field label="Fleetio Vendor ID" error={form.formState.errors.fleetioId?.message}>
              <TextInput type="number" {...form.register('fleetioId')} />
            </Field>
          )}
        </form>
      </div>
      <div
        className={classNames(
          'py-3 px-6 flex',
          showCancelButton ? 'justify-between' : 'justify-end',
          !transparentFooter && 'bg-gray-50'
        )}
      >
        {showCancelButton && <Action.S onClick={onCancel || onClose}>Back</Action.S>}

        <Action.P
          color="blue"
          className="font-medium"
          onClick={form.handleSubmit(onSubmit)}
          disabled={!form.formState.isDirty}
          performing={saving}
        >
          Save
        </Action.P>
      </div>
    </FocusTrap>
  )
}

export default AddVendorModal
