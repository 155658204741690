import { Td } from '@/gf/components/Table'
import { Transition } from '@headlessui/react'
import classNames from 'classnames'

const ActionCells = ({
  showActionDivider,
  firstOrLast,
  children,
}: {
  showActionDivider: boolean | undefined
  firstOrLast: boolean
  children: React.ReactNode
}) => (
  <>
    <Td className="hidden sm:table-cell w-0 sticky right-0 z-10 p-0">
      <div className="absolute inset-0 z-30 bg-white bg-opacity-90" />

      <Transition
        show={!!showActionDivider}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={classNames(
            'w-4 absolute -left-4 z-20 opacity-5 bg-gradient-to-l from-black',
            firstOrLast ? 'inset-y-0' : '-inset-y-px'
          )}
        />
      </Transition>

      <div className="px-3 py-1.5 relative z-40">{children}</div>
    </Td>

    <Td className="table-cell sm:hidden p-0">
      <div className="px-3 py-1.5 relative z-40">{children}</div>
    </Td>
  </>
)

export default ActionCells
