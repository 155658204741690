import Action from '@/gf/components/Action'
import { ExclamationIcon } from '@heroicons/react/outline'

const RedBanner = ({ children }) => (
  <div className="bg-red-500 font-medium text-lg text-white text-center p-3">{children}</div>
)

const RequestTutorialBanner = () => (
  <RedBanner>
    <ExclamationIcon className="inline-block h-5 w-5 -mt-0.5" /> This request was created by the
    tutorial and has no real value.
  </RedBanner>
)

const OrderTutorialBanner = () => (
  <RedBanner>
    <ExclamationIcon className="inline-block h-5 w-5 -mt-0.5" /> This order was created by the
    tutorial and has no real value.
  </RedBanner>
)

const CreateRequestTutorialBanner = ({ onLeaveClick }: { onLeaveClick: () => void }) => (
  <div className="bg-red-500 font-medium text-lg text-white text-center p-3">
    This is a tutorial & no real parts will be ordered{' '}
    <Action.S onClick={onLeaveClick} size="sm" className="ml-4">
      Leave Tutorial
    </Action.S>
  </div>
)

export { CreateRequestTutorialBanner, RequestTutorialBanner, OrderTutorialBanner }
