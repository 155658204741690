import classNames from 'classnames'
import uniq from 'lodash/uniq'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  SuggestedPeriodicPartRequestsDocument,
  SuggestedPeriodicPartRequestsQuery,
  useDismissSuggestedPartMutation,
  useSuggestedPeriodicPartRequestsQuery,
} from '@/buyers/_gen/gql'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import Box from '@/gf/components/Box'
import Action from '@/gf/components/Action'
import pluralize from 'pluralize'
import { DateTime } from 'luxon'
import { CalendarIcon, ClipboardCheckIcon, ClockIcon } from '@heroicons/react/outline'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Time from '@/gf/modules/Time'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import useSession from '@/buyers/hooks/useSession'
import useRequestSuggestedPart from '../Reporting/Consumption/useRequestSuggestedPart'
import Link from '@/gf/components/Link'

const machineName = ({ make, model, year }) => [year, make, model].filter((p) => !!p).join(' ')

const SuggestedPartCard = ({
  suggestion: s,
}: {
  suggestion: SuggestedPeriodicPartRequestsQuery['suggestedPeriodicPartRequests'][number]
}) => {
  const client = useGqlClient()

  const [dismiss, { loading: dismissing }] = useDismissSuggestedPartMutation({
    client,
    refetchQueries: [SuggestedPeriodicPartRequestsDocument],
    variables: { id: s.id },
  })

  const createRequest = useRequestSuggestedPart(s.partNumber, s.partDescription, s.requestQty, null)

  const machineNames = uniq(s.orgMachines.map((om) => (om ? machineName(om?.machine) : '')))

  return (
    <Box
      className={classNames('shadow-base p-4 space-y-3', dismissing && 'opacity-75 bg-gray-100')}
    >
      <hgroup className="truncate">
        <h5 className="text-xl font-medium truncate text-ellipsis" title={s.partDescription}>
          {s.partDescription}
        </h5>
        <p className="text-sm text-gray-500 font-medium text-ellipsis">
          #{s.partNumber}{' '}
          {machineNames.length > 0 && (
            <>
              for{' '}
              {machineNames.length === 1 ? (
                machineNames[0] ? (
                  machineNames[0]
                ) : (
                  ''
                )
              ) : (
                <Tooltip placement="bottom">
                  <TooltipTrigger className="text-left">multiple machines</TooltipTrigger>
                  <TooltipContent className="z-[99999]">
                    <p
                      className={classNames(
                        'max-w-96 bg-gray-700 text-gray-50 border border-gray-900 px-3 py-2 text-sm rounded-md'
                      )}
                    >
                      {machineNames.join(', ')}
                    </p>
                  </TooltipContent>
                </Tooltip>
              )}
            </>
          )}
        </p>
      </hgroup>

      <div className="text-sm text-gray-600">
        <p className="flex items-center gap-x-1.5">
          <ClipboardCheckIcon className="text-gray-600 inline-block w-4 h-4 flex-shrink-0" />
          <Link.T to={`/reporting/consumption?selected=${s.id}&search=${s.partDescription}`}>
            {s.numOfOrders} {pluralize('order', s.numOfOrders)}
          </Link.T>
        </p>
        {s.requestFrequencySec && (
          <p className="flex items-center gap-x-1.5">
            <ClockIcon className="text-gray-600 inline-block w-4 h-4 flex-shrink-0" />
            {s.requestQty} every {Time.secondsToString2(s.requestFrequencySec)} (last request{' '}
            {s.lastRequestedAt.toLocaleString(DateTime.DATE_SHORT)})
          </p>
        )}

        {s.neededBy && (
          <p className="flex items-center gap-x-1.5">
            <CalendarIcon className="text-gray-600 inline-block w-4 h-4 flex-shrink-0" />
            Needed {s.neededBy.toRelative()} ({s.neededBy.toLocaleString(DateTime.DATE_SHORT)})
          </p>
        )}
      </div>

      <div className="flex justify-between">
        <Action.S
          onClick={() => dismiss()}
          performing={dismissing}
          className="font-medium"
          size="sm"
        >
          Dismiss
        </Action.S>
        <Action.P
          color="blue"
          onClick={() => createRequest()}
          className="font-medium"
          size="sm"
          disabled={dismissing}
        >
          Request Part
        </Action.P>
      </div>
    </Box>
  )
}

const SuggestedPeriodicPartRequests = () => {
  const client = useGqlClient()
  const { orgId } = useSession()
  const { data, previousData } = useSuggestedPeriodicPartRequestsQuery({
    client,
    variables: { orgId },
  })

  const suggestions =
    data?.suggestedPeriodicPartRequests ?? previousData?.suggestedPeriodicPartRequests ?? []

  if (suggestions.length === 0) {
    return null
  }

  return (
    <div className="px-6 pt-5 pb-6 bg-gray-50 space-y-4 rounded-xl border border-gray-200 shadow-base">
      <div className="flex justify-between gap-x-3">
        <hgroup>
          <h4 className="text-2xl font-medium text-gray-900 flex items-center gap-x-4">
            Predicted Part Requests{' '}
            <Link.T to="/reporting/consumption" className="text-base font-normal">
              View All
            </Link.T>
          </h4>
          <p className="text-lg">
            Based on your order history, we&apos;ve identified parts that may need to be reordered
            soon.
          </p>
        </hgroup>

        <div className="flex items-center gap-x-2 h-8">
          <button type="button" className="arrow-left text-gray-700 flex-shrink-0">
            <ChevronLeftIcon className="inline-block w-6 h-6" />
          </button>
          <span className="pagination flex-shrink-0 block w-auto text-gray-600 text-xs font-medium" />
          <button type="button" className="arrow-right text-gray-700 flex-shrink-0">
            <ChevronRightIcon className="inline-block w-6 h-6" />
          </button>
        </div>
      </div>

      <Swiper
        modules={[Navigation, A11y, Pagination]}
        spaceBetween={15}
        slidesPerView={1}
        navigation={{
          enabled: true,
          nextEl: '.arrow-right',
          disabledClass: 'text-gray-400',
          prevEl: '.arrow-left',
        }}
        pagination={{
          type: 'fraction',
          el: '.pagination',
          renderFraction: (currentClass, totalClass) =>
            `<span class="${currentClass}"></span> of <span class="${totalClass}"></span>`,
        }}
        breakpoints={{
          '768': {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          '1268': {
            slidesPerView: 3,
            slidesPerGroup: 2,
          },
        }}
      >
        {suggestions.map((s) => (
          <SwiperSlide key={s.id}>
            <SuggestedPartCard suggestion={s} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SuggestedPeriodicPartRequests
