import { CheckIcon, DocumentIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import pluralize from 'pluralize'

import RequestForQuoteM from '@/gf/modules/RequestForQuote'
import Time from '@/gf/modules/Time'

import {
  LocationQuery,
  MachineActivityQuery,
  StoreOrderStep,
  VendorActivityQuery,
} from '@/buyers/_gen/gql'

import RfqStepBadge from '@/buyers/components/RfqStepBadge'
import Link from '@/gf/components/LinkOld'
import { HeroIcon } from '@/types'
import RFQCardGhost from './RFQCardGhost'

type RequestForQuote =
  | MachineActivityQuery['searchRequests']['requests'][number]
  | LocationQuery['searchRequests']['requests'][number]
  | VendorActivityQuery['searchRequests']['requests'][number]

interface QuoteCount {
  count: number
  label: string
  counterClassName: string
  icon: HeroIcon
}

const QuoteList = ({ counts }: { counts: QuoteCount[] }) => (
  <ul className="rounded-lg border border-slate-200 overflow-hidden">
    {counts.map((count) => (
      <li key={count.label} className="flex border-t border-slate-200 first:border-t-0">
        <span
          className={classNames(
            'text-sm text-zinc-500 pl-2 pr-4 py-2 flex items-center leading-4 flex-grow'
          )}
        >
          <count.icon className="h-4 text-gearflow inline-block mr-2" /> {count.label}
        </span>
        <span
          className={classNames(
            'font-semibold leading-4 w-12 text-lg flex items-center justify-center itens-center px-1',
            count.counterClassName
          )}
        >
          {count.count}
        </span>
      </li>
    ))}
  </ul>
)

const approvedSteps = [StoreOrderStep.PoSent, StoreOrderStep.Fulfilling, StoreOrderStep.Fulfilled]

const RFQCard = ({
  requestForQuote,
  loading,
  className,
}: {
  requestForQuote: RequestForQuote | null
  loading: boolean
  className?: string
}) => {
  if (loading) return <RFQCardGhost />

  if (!requestForQuote) return null

  const receivedQuotes = requestForQuote.storeOrders.length > 0

  return (
    <section className={classNames('border rounded-lg', className)}>
      <header className="flex justify-between items-center px-3 border-b">
        <div className="pb-2 pt-2">
          <h3 className="text-xl text-slate-600 leading-5">Latest Request</h3>
          <p className="text-gray-500 italic text-xs mt-1">
            {Time.toString(requestForQuote.insertedAt)}
          </p>
        </div>
        <div>
          <RfqStepBadge step={requestForQuote.step} />
        </div>
      </header>
      <div className="flex flex-col sm:flex-row justify-between">
        <div className="text-slate-600 flex-grow">
          <h4 className="text-xs text-zinc-400 font-bold tracking-wide uppercase pt-2 pb-1 pl-3">
            Request
          </h4>
          <ul className="px-3 -mt-1">
            {requestForQuote.parts.map((part) => (
              <li
                key={part.id}
                className={classNames('w-full py-1.5 border-slate-100 border-t first:border-0')}
              >
                <div className="flex gap-x-4">
                  <span className="font-medium text-zinc-500 text-sm">
                    Part Number{' '}
                    <span className="text-slate-800 font-semibold">{part.mpn || 'N/A'}</span>
                  </span>
                  <span className="font-semibold text-slate-800 tracking-wide text-sm">
                    {part.quantity} {pluralize('item', part.quantity)}
                  </span>
                </div>
                <p className="text-zinc-600 text-sm">{part.description}</p>
              </li>
            ))}
          </ul>

          {requestForQuote.partsRequest && (
            <>
              <h4 className="text-xs text-zinc-400 font-bold tracking-wide uppercase pt-2 pb-1 pl-3">
                Additional Details
              </h4>
              <p className="px-3 pb-2 leading-4 prose text-sm text-zinc-600">
                {requestForQuote.partsRequest}
              </p>
            </>
          )}
        </div>

        <div className="flex-shrink-0 sm:border-l px-3 w-full sm:w-48 py-2">
          <h4 className="text-xs text-zinc-400 font-bold tracking-wide uppercase pb-2">
            {receivedQuotes ? 'Quotes' : 'No quotes'}
          </h4>
          <div className="flex flex-col gap-y-2">
            {receivedQuotes ? (
              <QuoteList
                counts={[
                  {
                    icon: DocumentIcon,
                    label: 'Received',
                    count: requestForQuote.storeOrders.length,
                    counterClassName: 'bg-gradient-to-r from-white to-slate-100 text-gearflow',
                  },
                  {
                    icon: CheckIcon,
                    label: 'Approved',
                    count: requestForQuote.storeOrders.filter((so) =>
                      approvedSteps.includes(so.step)
                    ).length,
                    counterClassName: 'bg-gradient-to-r from-white to-slate-100 text-gearflow',
                  },
                ]}
              />
            ) : (
              <p className="text-sm leading-4 text-zinc-400">
                Your request hasn&apos;t been matched to a supplier yet.
              </p>
            )}
          </div>
        </div>
      </div>
      <footer className="border-t rounded-b-lg bg-slate-50 flex justify-between items-center py-2 px-4">
        <Link
          to={`/rfqs/${requestForQuote.id}`}
          title={`Request ID ${RequestForQuoteM.shortenId(requestForQuote.id)}`}
        >
          View Request
        </Link>

        <p className="text-sm text-zinc-500 text-right">
          <span className="block sm:inline text-left">Created By</span>{' '}
          <span className="font-medium text-zinc-700">{requestForQuote.user?.name}</span>
        </p>
      </footer>
    </section>
  )
}

export default RFQCard
