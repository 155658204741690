import { useCompleteVendorSetupMutation, useVendorToCompleteQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import Field from '@/gf/components/next/forms/Field'
import PhoneInput from '@/gf/components/next/forms/PhoneInput'
import TextInput from '@/gf/components/next/forms/TextInput'
import Select from '@/gf/components/next/Select'
import { Maybe, ModalSize } from '@/types'
import { yupResolver } from '@hookform/resolvers/yup'
import { DocumentNode } from 'graphql'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { StringParam, useQueryParams } from 'use-query-params'
import * as Yup from 'yup'
import MicrosoftContacts, { Contact } from './CompleteVendorModal/MicrosoftContacts'

const validationSchema = Yup.object().shape({
  accountNumber: Yup.string().required('Account Number is required'),
  contactName: Yup.string().required('Name is required'),
  contactEmail: Yup.string().email().required('Email is required'),
  contactPhone: Yup.string().optional(),
})

type FormValues = {
  accountNumber: string
  contactName: string
  contactEmail: string
  contactPhone: Maybe<string>
}

const CompleteVendorModal = ({
  open,
  vendorId,
  refetchQueries,
  onSuccess = () => undefined,
  ...props
}: {
  open: boolean
  onClose: () => void
  vendorId: string
  refetchQueries?: DocumentNode[]
  onSuccess?: (vendorId: string) => void
}) => {
  const { featureFlags } = useSession()
  const client = useGqlClient()
  const [creatingContact, setCreatingContact] = useState(false)
  const [completeSetup, { loading: saving }] = useCompleteVendorSetupMutation({ client })
  const { data } = useVendorToCompleteQuery({ variables: { vendorId }, skip: !open, client })
  const vendor = data?.vendor ?? null

  const [query, setQuery] = useQueryParams({
    accountNumber: StringParam,
    contactName: StringParam,
    contactEmail: StringParam,
    contactPhone: StringParam,
  })

  const clearQuery = () =>
    setQuery({
      accountNumber: undefined,
      contactName: undefined,
      contactEmail: undefined,
      contactPhone: undefined,
    })

  const { register, formState, control, handleSubmit, watch, setValue, reset } =
    useForm<FormValues>({ resolver: yupResolver(validationSchema) })

  useEffect(() => {
    if (query.accountNumber) setValue('accountNumber', query.accountNumber)
    if (query.contactName) setValue('contactName', query.contactName)
    if (query.contactEmail) setValue('contactEmail', query.contactEmail)
    if (query.contactPhone) setValue('contactPhone', query.contactPhone)
    clearQuery()
  }, [])

  useEffect(() => {
    setCreatingContact(vendor?.dealerLocation ? vendor.dealerLocation.contacts.length === 0 : true)

    if (vendor?.dealerLocation) {
      reset({
        accountNumber: '',
        contactName: '',
        contactEmail: vendor.dealerLocation?.email ?? '',
        contactPhone: vendor.dealerLocation?.phone ?? '',
      })
    }
  }, [vendor?.dealerLocation])

  const contactEmail = watch('contactEmail')

  const selectedContactOption = useMemo(() => {
    if (!contactEmail || !vendor?.dealerLocation) {
      return null
    }

    const selected = vendor.dealerLocation.contacts.find((c) => c.email === contactEmail)

    return selected ? { label: selected.name, value: selected.email } : null
  }, [contactEmail, vendor?.dealerLocation, creatingContact])

  const contactOptions = useMemo(
    () =>
      vendor?.dealerLocation
        ? vendor?.dealerLocation.contacts.map((c) => ({
            value: c.email,
            label: c.name,
          }))
        : [],
    [vendor?.dealerLocation]
  )

  const onClose = () => {
    clearQuery()
    props.onClose()
  }

  const onSubmit = async (values: FormValues) => {
    try {
      await completeSetup({
        variables: {
          vendorId,
          accountNumbers: [values.accountNumber],
          contact: {
            name: values.contactName,
            email: values.contactEmail,
            phone: values.contactPhone || null,
          },
        },
        refetchQueries,
      })

      onClose()
      onSuccess(vendorId)
    } catch (err) {
      console.error(err)
    }
  }

  if (!vendor) return null

  const microsoftContactSelected = (contact: Contact) => {
    setValue('contactName', contact.name)
    setValue('contactEmail', contact.emailAddress)
    setValue('contactPhone', contact.phone)
  }

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.SM}>
      <form className="relative p-6 space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />

        <div className="flex gap-x-3">
          {vendor.dealerLocation?.logoUrl && (
            <div className="w-16 h-16 flex-shrink-0 rounded-full border shadow-base overflow-hidden">
              <img
                src={vendor.dealerLocation?.logoUrl}
                alt=""
                className="h-full w-full object-contain"
              />
            </div>
          )}
          <div className="flex flex-col justify-center gap-y-1">
            <p className="text-xl font-medium">{vendor.name}</p>
            {vendor.address && (
              <p className="text-gray-500 text-base">
                {vendor.address?.lineOne}
                <br />
                {vendor.address?.city}, {vendor.address?.state} {vendor.address?.postalCode}
              </p>
            )}
          </div>
        </div>

        <Field
          label="Account Number"
          help={`What's your account number with ${vendor.name}?`}
          error={formState.errors?.accountNumber?.message}
        >
          <TextInput {...register('accountNumber')} />
        </Field>

        <div className="pt-4 space-y-4">
          <div className="pt-6 border-t">
            <div className="flex flex-col">
              <p className="text-base text-gray-900">Who do you order from at {vendor.name}?</p>
              <p className="text-sm text-gray-500">
                You can add more contacts later, after saving these vendor details.
              </p>
            </div>
          </div>

          {creatingContact ? (
            <>
              {featureFlags.microsoftContacts && (
                <MicrosoftContacts
                  onSelect={microsoftContactSelected}
                  redirectParams={() => {
                    const params = new URLSearchParams(window.location.search)

                    Object.entries(watch()).forEach(([key, val]) => {
                      if (val) params.append(key, val)
                    })

                    return params
                  }}
                />
              )}

              <Field
                label="Account Representative Name"
                error={formState.errors?.contactName?.message}
              >
                <TextInput {...register('contactName')} />
              </Field>

              <Field
                label="Account Representative Email Address"
                error={formState.errors?.contactEmail?.message}
              >
                <TextInput {...register('contactEmail')} />
              </Field>

              <Controller
                control={control}
                name="contactPhone"
                render={({ field }) => (
                  <Field
                    label="Account Representative Phone Number"
                    error={formState.errors?.contactPhone?.message}
                  >
                    <PhoneInput value={field.value ?? ''} onChange={field.onChange} />
                  </Field>
                )}
              />
            </>
          ) : (
            <>
              <Field
                label="Account Representative"
                error={
                  formState.errors.contactName?.message ||
                  formState.errors.contactEmail?.message ||
                  formState.errors.contactPhone?.message
                    ? 'Account Representative information is required'
                    : null
                }
              >
                <Select
                  value={selectedContactOption}
                  options={contactOptions}
                  onChange={(o) => {
                    if (!o?.value || !vendor?.dealerLocation) {
                      return
                    }

                    const contact = vendor.dealerLocation.contacts.find((c) => c.email === o.value)
                    if (contact) {
                      setValue('contactName', contact.name)
                      setValue('contactEmail', contact.email ?? vendor.dealerLocation.email)
                      setValue('contactPhone', contact.phoneNumber ?? vendor.dealerLocation.phone)
                    }
                  }}
                  placeholder="Select your contact with the vendor"
                />
              </Field>
              <Action.T
                onClick={() => {
                  setCreatingContact(true)

                  setValue('contactName', '')
                  setValue('contactEmail', '')
                  setValue('contactPhone', '')
                }}
              >
                Add Contact
              </Action.T>
            </>
          )}
        </div>

        <div className="flex justify-end gap-x-4 pt-2">
          <Action.S onClick={onClose}>Cancel</Action.S>
          <Action.P type="submit" color="blue" performing={saving}>
            Save Vendor Details
          </Action.P>
        </div>
      </form>
    </Modal>
  )
}

export default CompleteVendorModal
