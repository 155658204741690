import Page from '@/gf/components/Page'
import useToggle from '@/gf/hooks/useToggle'
import { US_CENTER } from '@/gf/modules/Map'
import { Maybe } from '@/types'
import pick from 'lodash/pick'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import {
  Navigate,
  Route,
  Routes,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  RequestForApprovalQuery,
  useApproveRequestMutation,
  useAssignUserMutation,
  useRequestForApprovalQuery,
} from '../_gen/gql'
import Frame from '../components/Frame'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'
import PartDetailsStep from './CreateRequest/PartDetailsStep'
import ProgressBar from './CreateRequest/ProgressBar'
import ReviewStep from './CreateRequest/ReviewStep'
import SelectVendorsStep from './CreateRequest/SelectVendorsStep'
import { CreateRequestState, Priority, SourcingType } from './CreateRequest/types'
import usePersistedState from './CreateRequest/usePersistedState'
import useVendorSelectionType from './CreateRequest/useVendorSelectionType'

type InitRequest = RequestForApprovalQuery['searchRequests']['requests'][number]

const stepsConfig = [
  { label: 'Part Details', stepPath: 'part-details', index: 0 },
  { label: 'Vendors', stepPath: 'vendors/*', index: 1 },
  { label: 'Review', stepPath: 'review', index: 2 },
]

const partsDetailsProgress = (request: CreateRequestState) => {
  const machineProgress = !request.machineInvolved ? 40 : request.machineOrgId ? 40 : 0
  const partsProgress = request.parts?.[0]?.partNumber || request.parts?.[0]?.description ? 50 : 0
  const urgencyProgress = request.urgency?.priority ? 10 : 0
  return machineProgress + partsProgress + urgencyProgress
}

const vendorsProgress = (request: CreateRequestState) => {
  const selectedDealers = request.dealerLocationIds.length > 0 ? 50 : 0
  const location = request.locationId ? 20 : 0
  const dealersProgress = selectedDealers + location

  const gfNwProgress = request.locationId ? 100 : 0

  return request.sourcing === SourcingType.GF_NETWORK ? gfNwProgress : dealersProgress
}

const stepProgressFns = [
  partsDetailsProgress,
  vendorsProgress,
  (_request: CreateRequestState) => 100,
]

export const evalProprity = (
  insertedAt: DateTime,
  neededBy: Maybe<DateTime>,
  machineDown: boolean
) => {
  if (machineDown) {
    return Priority.HIGH
  }

  if (!neededBy) {
    return Priority.MEDIUM
  }

  if (neededBy.diff(insertedAt).as('days') > 6) {
    return Priority.LOW
  }

  if (neededBy.diff(insertedAt).as('days') > 1) {
    return Priority.MEDIUM
  }

  return Priority.HIGH
}

const ApproveRequestNext = () => {
  const client = useGqlClient()
  const location = useLocation()
  const navigate = useNavigate()
  const [initRequest, setInitRequest] = useState<InitRequest>()
  const { rfqId } = useParams() as { rfqId: string }
  const [initialized, initializeToggle] = useToggle()
  const { values, update, reset } = usePersistedState('aprove-request-next-state')
  const {
    orgId,
    organization,
    user: { id: userId },
  } = useSession()
  const [approveRequestMutation, { loading: approveRequestInProgress }] = useApproveRequestMutation(
    { client }
  )
  const [assignUserMutation, { loading: assignUserInProgress }] = useAssignUserMutation({ client })

  const basePath = `/rfqs/${rfqId}/approve`

  const initForm = (rfq: InitRequest) => {
    const dealerLocationIds = rfq.vendorLinks
      .map((vl) => vl.vendor?.dealerLocation?.id ?? vl.vendor?.id)
      .filter((dlId) => !!dlId)

    const machineOrgId = rfq.orgMachines[0]?.id || null

    update({
      billingCompanyId: rfq.billingCompanyId,
      workOrderNumber: '',
      machineOrgId,
      machineInvolved: !!machineOrgId,
      urgency: {
        machineDown: rfq.machineDown,
        neededByDate: rfq.neededBy
          ? rfq.neededBy
          : rfq.machineDown
            ? rfq.insertedAt.plus({ days: 1 })
            : rfq.insertedAt.plus({ days: 3 }),
        priority: evalProprity(rfq.insertedAt, rfq.neededBy, rfq.machineDown),
      },
      parts: rfq.parts.map((p, i) => ({
        partNumber: p.mpn,
        description: p.description,
        quantity: p.quantity,
        externalId: p.externalId,
        taskNumber: p.taskNumber,
        suggestion: p.suggestion,
        pictures: i === 0 ? rfq.images.map(({ url }) => url) : [],
      })),
      comments: rfq.partsRequest ?? '',
      sourcing:
        rfq.resumeDealer || rfq.vendorLinks.length > 0
          ? SourcingType.VENDORS
          : SourcingType.GF_NETWORK,
      dealerLocationIds,
      vendors: rfq.vendorLinks.map((vl) => ({
        vendorId: vl.vendor.id,
        contactIds: vl.vendorContacts.map((vc) => vc.id),
        deliveryMethod: vl.deliveryMethod,
        accountNumber: vl.accountNumber ?? '',
        fleetioId: null,
      })),
      locationId: rfq.shippingAddress?.location?.id,
      nearbyReferencePoint: rfq.shippingAddress?.point
        ? pick(rfq.shippingAddress.point, 'lat', 'lng')
        : US_CENTER,
    })

    initializeToggle.on()
  }

  const { data } = useRequestForApprovalQuery({
    client,
    variables: { orgId, filter: JSON.stringify(['id_eq', rfqId]) },
    onCompleted({ searchRequests }) {
      const rfq = searchRequests.requests[0]
      initForm(rfq)
      setInitRequest(rfq)
    },
  })

  const org = data?.org ?? null

  const resetForm = () => {
    reset()
    navigate('part-details')
    initForm(initRequest as InitRequest)
  }

  const currentStep = useMemo(() => {
    const matchingStep = stepsConfig.find(
      (step) => !!matchPath({ path: `${basePath}/${step.stepPath}` }, location.pathname)
    )
    const step = matchingStep ?? stepsConfig[0]

    return { ...step, progress: stepProgressFns[step.index](values) }
  }, [location.pathname, values])

  const onPartDetailsSubmitted = () => navigate(`vendors`)

  const onVendorsSubmitted = () => navigate(`review`)

  const onApproveRequest = async () => {
    const imageUrls = values.parts?.[0].pictures ?? []

    await assignUserMutation({ variables: { userId, rfqId } })

    const result = await approveRequestMutation({
      variables: {
        id: rfqId,
        billingCompanyId: values.billingCompanyId ?? null,
        splitOrder: false,
        orgMachineId: values.machineOrgId || null,
        vendorIds:
          values.sourcing === SourcingType.VENDORS ? values.vendors.map((vi) => vi.vendorId) : [],
        vendors:
          values.sourcing === SourcingType.VENDORS
            ? values.vendors.map((v) => ({
                vendorContactIds: v.contactIds,
                deliveryMethod: v.deliveryMethod,
                id: v.vendorId,
                customerNote: null,
                vendorContactId: null,
                pickup: null,
                accountNumber: v.accountNumber,
              }))
            : [],
        shippingLocationIds: [values.locationId as string],
        partRequests:
          values.parts?.map((part) => ({
            mpn: part.partNumber,
            description: part.description,
            quantity: part.quantity,
            externalId: part.externalId,
            taskNumber: part.taskNumber,
            suggestion: part.suggestion,
          })) ?? null,
        imageUrls,
        neededBy: values.urgency?.neededByDate as DateTime,
        machineDown: values.urgency?.machineDown as boolean,
      },
    })

    if (result.data) {
      reset()

      navigate(`/rfqs/${rfqId}?newRequest=1&init=1`)
    } else {
      console.error(result.errors)
    }
  }

  const vendorSelectionType = useVendorSelectionType(org)

  if (!org || !initialized) {
    return null
  }

  return (
    <Frame>
      <Page title="Approve Request">
        <div className="flex flex-col gap-y-8 mt-8 pb-12">
          <ProgressBar
            steps={stepsConfig.map((s) => s.label)}
            currentStep={currentStep.index}
            currentStepProgress={currentStep.progress}
          />
          <div className="space-y-4">
            <h3 className="text-2xl text-gray-900">{currentStep.label}</h3>
            <Routes>
              <Route path="" element={<Navigate to="review" replace />} />
              <Route path="details" element={<Navigate to="../part-details" replace />} />
              <Route path="dealers" element={<Navigate to="../vendors/select" replace />} />
              <Route
                path="part-details"
                element={
                  <PartDetailsStep
                    request={values}
                    updateRequest={update}
                    onSubmit={onPartDetailsSubmitted}
                    billingCompanyRequired={organization.requireBillingCompany ?? false}
                    workOrderNumberRequired={false}
                    commentsEnabled={false}
                    uploadPartsEnabled={false}
                    rfqId={rfqId}
                    submitButtonText="Next"
                    filfillFromInventoryMessageEnabled
                  />
                }
              />
              <Route
                path="vendors/*"
                element={
                  <SelectVendorsStep
                    reset={resetForm}
                    request={values}
                    updateRequest={update}
                    onSubmit={onVendorsSubmitted}
                    onBackClicked={() => navigate('part-details')}
                    sourcingTypeEnabled={false}
                    vendorSelectionType={vendorSelectionType}
                  />
                }
              />
              <Route
                path="review"
                element={
                  <ReviewStep
                    reset={resetForm}
                    request={values}
                    updateRequest={update}
                    onBackClicked={() => navigate('vendors')}
                    onCreateRequestClicked={onApproveRequest}
                    submitButtonText="Approve Request"
                    submitInProgress={approveRequestInProgress || assignUserInProgress}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      </Page>
    </Frame>
  )
}

export default ApproveRequestNext
