import { VendorsQuery } from '@/buyers/_gen/gql'
import Dropdown from '@/gf/components/next/Dropdown'
import DropdownAction from '@/gf/components/next/DropdownAction'
import Phone from '@/gf/modules/Phone'

type Contacts = Exclude<
  VendorsQuery['org'],
  null
>['paginatedVendors']['vendors'][number]['contacts']

const Contact = ({ contact }: { contact: Contacts[number] }) => (
  <span className="block">
    <span className="block text-sm">{contact.name ?? contact.email}</span>
    {contact.phoneNumber && (
      <span className="block text-xs text-gray-600 mt-1">{Phone.format(contact.phoneNumber)}</span>
    )}
  </span>
)

const ContactDropdown = ({ contacts }: { contacts: Contacts }) =>
  contacts.length > 1 ? (
    <Dropdown
      trigger={
        <DropdownAction>
          <span className="flex gap-x-2 flex-grow">
            <Contact contact={contacts[0]} />

            {contacts.length > 1 && (
              <span className="font-medium text-xs bg-gray-100 border border-gray-200 text-gray-600 rounded-md px-1.5 py-1 leading-1 self-start">
                +{contacts.length - 1}
              </span>
            )}
          </span>
        </DropdownAction>
      }
      placement="bottom-end"
    >
      <ul className="text-sm bg-white rounded-lg border shadow-md min-w-48">
        {contacts
          .filter((c) => c.id !== contacts[0].id)
          .map((c) => (
            <li className="border-b last:border-0 p-2">
              <Contact contact={c} />
            </li>
          ))}
      </ul>
    </Dropdown>
  ) : (
    <Contact contact={contacts[0]} />
  )

export default ContactDropdown
