import {
  PermissionSummary,
  Role,
  useAddUserMutation,
  useAllPlansQuery,
  useUsersQuery,
} from '@/buyers/_gen/gql'
import type { AdminUserFormReducer } from '@/buyers/hooks/useAddUserForm'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import usePlanQuery, { PlanPaymentMethod, PlanSubscription } from '@/buyers/hooks/usePlanQuery'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import Desc from '@/gf/components/Desc'
import Link from '@/gf/components/Link'
import Modal from '@/gf/components/Modal'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import MoneyM from '@/gf/modules/Money'
import type { Money, Plan } from '@/types'
import { CreditCardIcon } from '@heroicons/react/outline'
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { GraphQLError } from 'graphql'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Actions from './Review/Actions'
import User from './Review/User'

const SubscriptionChanges = ({
  fee,
  activeUserCount,
  licenses,
  stripePlan,
  planSubscription,
  planPaymentMethod,
}: {
  fee: Money
  activeUserCount: number
  licenses: number
  stripePlan: Plan
  planSubscription: PlanSubscription
  planPaymentMethod: PlanPaymentMethod | null
}) => {
  const basePrice = planSubscription
    ? (planSubscription.plan?.basePrice ?? MoneyM.fromInt(stripePlan?.basePrice ?? 0, 'USD'))
    : MoneyM.fromInt(stripePlan?.basePrice ?? 0, 'USD')

  return (
    <Card.Section title={`Subscription${activeUserCount >= licenses ? ' Changes' : ''}`}>
      <div className="space-y-4">
        {licenses > activeUserCount ? (
          <div className="bg-yellow-50 border-1 rounded-md border-yellow-400 px-4 py-3 prose inline-block">
            Your subscription will not change. You have enough licenses to add this user.
          </div>
        ) : (
          <>
            <div className="prose">
              Additional licenses are {MoneyM.format(fee)} {stripePlan.frequency}. See the{' '}
              <Link.T to="/settings/billing/details">Plan Details</Link.T> page for more info.
            </div>
            <Desc.List>
              <Desc.Row title="Plan">Parts Hub Pro</Desc.Row>
              <Desc.Row title="Licenses">
                <div className="flex items-center gap-8">
                  <div className="flex gap-4">
                    {activeUserCount >= licenses && (
                      <span className="text-gray-500 line-through">{activeUserCount}</span>
                    )}{' '}
                    <span>{activeUserCount + 1}</span>
                  </div>
                </div>
              </Desc.Row>
              <Desc.Row
                title={
                  <>
                    <span className="capitalize">{stripePlan.frequency}</span> Total
                  </>
                }
              >
                <div className="flex gap-4">
                  {activeUserCount >= licenses && (
                    <span className="text-gray-500 line-through">
                      {MoneyM.format(
                        MoneyM.add(MoneyM.mult(fee, planSubscription.quantity), basePrice)
                      )}
                    </span>
                  )}{' '}
                  <span>
                    {MoneyM.format(
                      MoneyM.add(MoneyM.mult(fee, planSubscription.quantity + 1), basePrice)
                    )}
                  </span>
                </div>
              </Desc.Row>
              {planPaymentMethod && (
                <Desc.Row title="Payment">
                  <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2">
                      <CreditCardIcon className="w-5 h-5" />
                      <div>
                        {planPaymentMethod.brand} ending in {planPaymentMethod.lastFour}
                      </div>
                    </div>
                    <div className="text-sm">
                      <Link.T to="/settings/billing/details">update</Link.T>
                    </div>
                  </div>
                </Desc.Row>
              )}
            </Desc.List>
          </>
        )}
      </div>
    </Card.Section>
  )
}

const Review = ({
  form: { fields, errors },
  reset,
  validate,
  roles,
  permissionSummaries,
  cancel,
  navigateToStepIndex,
}: AdminUserFormReducer & {
  cancel: () => void
  navigateToStepIndex: (index: number) => void
  roles: Pick<Role, 'id' | 'name'>[] | undefined
  permissionSummaries: Pick<PermissionSummary, 'title' | 'roles'>[] | undefined
}) => {
  const { user, organization } = useSession()
  const orgId = organization.id
  const client = useGqlClient()
  const planResult = usePlanQuery({ variables: { orgId }, client })
  const plans = useAllPlansQuery({ client })
  const [_, msgr] = useMsgs()
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const [addUserMutation] = useAddUserMutation({ client, refetchQueries: ['PendingInvites'] })
  const stripe = useStripe()
  const elements = useElements()
  const [makePaymentOpen, makePaymentToggler] = useToggle()

  const stripePlan = plans?.data?.allPlans.find(
    (p) => p.subscriptionType === 'organization'
  ) as Plan

  const activeUserCount = useUsersQuery({ variables: { orgId }, client }).data?.org?.users.filter(
    (u) => u.active
  ).length

  if (!stripePlan || !planResult.data || activeUserCount === undefined) return null

  const { planSubscription, planPaymentMethod } = planResult.data

  const addUser = () => {
    setSubmitting(true)

    validate()
      .then(async () => {
        let paymentMethodId: string | null = null

        if (planPaymentMethod) {
          paymentMethodId = planPaymentMethod.externalId
        } else if (
          planSubscription &&
          planSubscription.plan.subscriptionType === 'php_fixed_plus'
        ) {
          paymentMethodId = 'invoice'
        } else if (
          !organization.buyerDashboardAccess &&
          planSubscription &&
          planSubscription.plan.subscriptionType !== 'php_fixed'
        ) {
          if (!stripe || !elements) {
            msgr.addUnknownError()
            return
          }

          const card = elements.getElement(CardNumberElement)

          const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: card as NonNullable<typeof card>,
            billing_details: { email: user.email },
          })

          if (error) {
            if (error.message) msgr.add(error.message, 'negative')
            else msgr.addUnknownError()
            return
          }

          paymentMethodId = paymentMethod.id
        }

        addUserMutation({
          variables: {
            ...fields,
            roleIds: [fields.roleId as string],
            paymentMethodId,
            orgId,
          },
        })
          .then(({ data }) => {
            planResult.refetch()
            reset()
            navigate(`/settings/users/add/success/${data?.addUser}`)
          })
          .catch((e: GraphQLError) => {
            if (e.message) msgr.add(e.message, 'negative')
            else msgr.addUnknownError()
          })
          .finally(() => setSubmitting(false))
      })
      .catch((err) => {
        if (errors.name || errors.email || errors.phoneNumber || errors.title) {
          navigate('/settings/users/add/details')
        } else if (errors.roleId) {
          navigate('/settings/users/add/permissions')
        } else {
          console.error(err)
        }
      })
  }

  const fee = planSubscription
    ? planSubscription.plan.amount
    : MoneyM.fromInt(stripePlan.amount, 'USD')
  const licenses = (planSubscription?.quantity || 0) + organization.freeLicenses

  const addlLicenseRequired =
    !organization.buyerDashboardAccess && planSubscription && licenses <= activeUserCount

  const onAdd = () => {
    if (addlLicenseRequired) makePaymentToggler.on()
    else addUser()
  }

  const title = planSubscription
    ? 'Update Subscription Confirmation'
    : 'New Subscription Confirmation'

  const basePrice = planSubscription
    ? (planSubscription.plan?.basePrice ?? MoneyM.fromInt(stripePlan?.basePrice ?? 0, 'USD'))
    : MoneyM.fromInt(stripePlan?.basePrice ?? 0, 'USD')

  return (
    <>
      {addlLicenseRequired && (
        <Modal
          cancelText="No"
          title={title}
          open={makePaymentOpen}
          onClose={makePaymentToggler.off}
          footerAction={
            <Action.P
              onClick={() => {
                makePaymentToggler.off()
                addUser()
              }}
            >
              Yes
            </Action.P>
          }
        >
          {planSubscription.plan.subscriptionType === 'php_fixed' ? (
            <p>Clicking &lsquo;Yes&rsquo; will add the user and activate 1 license</p>
          ) : (
            <p>
              Clicking &lsquo;Yes&rsquo; will
              {planSubscription ? (
                <> change the {stripePlan.frequency} subscription to </>
              ) : (
                <> add a {stripePlan.frequency} subscription for </>
              )}
              {MoneyM.format(
                MoneyM.add(MoneyM.mult(fee, planSubscription.quantity + 1), basePrice)
              )}
            </p>
          )}
        </Modal>
      )}
      <Card>
        <User fields={fields} roles={roles} permissionSummaries={permissionSummaries} />

        {addlLicenseRequired && planSubscription.plan.subscriptionType !== 'php_fixed' && (
          <SubscriptionChanges
            fee={fee}
            activeUserCount={activeUserCount}
            licenses={licenses}
            stripePlan={stripePlan}
            planSubscription={planSubscription}
            planPaymentMethod={planPaymentMethod}
          />
        )}
        <Actions
          onAdd={onAdd}
          cancel={cancel}
          submitting={submitting}
          navigateToStepIndex={navigateToStepIndex}
        />
      </Card>
    </>
  )
}

export default Review
