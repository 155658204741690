import { CreateRequestSelectLocationsDocument, useAllBranchesQuery } from '@/buyers/_gen/gql'
import LocationModal from '@/buyers/components/LocationModal'
import NoAccess from '@/buyers/components/NoAccess'
import PartHubProPitch from '@/buyers/components/PartHubProPitch'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import useToggle from '@/gf/hooks/useToggle'
import AddressM from '@/gf/modules/Address'
import { Point } from '@/types'
import classNames from 'classnames'
import isNumber from 'lodash/isNumber'
import { useNavigate } from 'react-router-dom'
import StartOverButton from '../StartOverButton'
import StickyBottomBar from '../StickyBottomBar'
import { PartialRequestUpdate, SourcingType, VendorSelectionType } from '../types'
import LocationsList from './MyVendors/LocationsList'
import { twMerge } from 'tailwind-merge'
import { SimpleTooltip } from '@/gf/components/next/Tooltip'

const SourcingButtonContent = ({ title, text }) => (
  <span className="block px-3 py-2 text-center">
    <span className="flex flex-col items-center justify-center space-y-2">
      <span className="font-medium text-lg block leading-6">{title}</span>
      <span className="block text-base leading-5">{text}</span>
    </span>
  </span>
)

const SourcingTypeButton = ({
  selected,
  title,
  text,
  onClick,
  disabled,
  disabledTooltip,
}: {
  selected?: boolean
  title: React.ReactNode
  text: React.ReactNode
  onClick: () => void
  disabled?: boolean
  disabledTooltip?: string
}) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={twMerge(
      'block h-32 transition duration-150 shadow-base rounded-xl',
      selected === true
        ? ' border-2 border-blue-600'
        : 'border border-gray-300 transition duration-200 hover:border-gray-400',
      selected === false && 'text-gray-500',
      disabled && 'bg-gray-100 cursor-not-allowed hover:border-gray-300'
    )}
  >
    {disabled && disabledTooltip ? (
      <SimpleTooltip text={disabledTooltip} placement="bottom">
        <SourcingButtonContent title={title} text={text} />
      </SimpleTooltip>
    ) : (
      <SourcingButtonContent title={title} text={text} />
    )}
  </button>
)

const Section = ({ children, className = '' }) => (
  <div className={classNames('max-w-[39rem] mx-auto', className)}>{children}</div>
)

const SourcingAndLocation = ({
  reset,
  request,
  updateRequest,
  onSubmit,
  onBackClicked,
  sourcingTypeEnabled = true,
  vendorSelectionType,
  gearflowNetworkSectionEnabled = true,
  onLocationChanged,
  orgHasLocations = true,
}: {
  reset?: () => void
  onSubmit: () => void
  onBackClicked: () => void
  sourcingTypeEnabled?: boolean
  vendorSelectionType: VendorSelectionType
  gearflowNetworkSectionEnabled?: boolean
  onLocationChanged: (point: Point, selectedLocationId?: string) => void
  orgHasLocations?: boolean
} & PartialRequestUpdate) => {
  const navigate = useNavigate()
  const client = useGqlClient()
  const { organization } = useSession()
  const [locationModalOpen, locationModalToggle] = useToggle()
  const branches = useAllBranchesQuery({ variables: { value: '' }, client }).data?.allBranches

  const changeSourcingType = (sourcingType: SourcingType) =>
    updateRequest({ sourcing: sourcingType })

  const onNext = () => {
    if (request.sourcing === SourcingType.GF_NETWORK) {
      updateRequest({ vendors: [], dealerLocationIds: [] })
      onSubmit()
    } else {
      navigate('../select')
    }
  }

  return (
    <>
      <LocationModal
        open={locationModalOpen}
        refetchQueries={[CreateRequestSelectLocationsDocument]}
        onClose={locationModalToggle.off}
        onComplete={(locationId, variables) =>
          onLocationChanged(variables.address?.point as Point, locationId)
        }
        branches={(organization.requireBillingCompany ? branches : []) ?? []}
        buyers={[]}
        initialAddress={AddressM.init()}
        showPersist
      />

      <Box className="shadow-base rounded-xl px-6 py-8">
        <div className="space-y-8">
          {sourcingTypeEnabled && (
            <>
              <Section className="space-y-6">
                <h4 className="text-xl font-medium text-gray-900">
                  Where would you like to order your parts from?
                </h4>

                <div className="grid grid-cols-2 gap-4">
                  <SourcingTypeButton
                    title="Your Vendors"
                    text="Order parts from the vendors you know and trust."
                    selected={
                      request.sourcing === undefined
                        ? undefined
                        : request.sourcing === SourcingType.VENDORS
                    }
                    onClick={() => changeSourcingType(SourcingType.VENDORS)}
                  />

                  {gearflowNetworkSectionEnabled && (
                    <SourcingTypeButton
                      title="Gearflow Supplier Network"
                      text={<>Source parts from hundreds of suppliers in North America.</>}
                      selected={
                        request.sourcing === undefined
                          ? undefined
                          : request.sourcing === SourcingType.GF_NETWORK
                      }
                      onClick={() => changeSourcingType(SourcingType.GF_NETWORK)}
                      disabled={request.tutorial}
                      disabledTooltip="Gearflow Supplier Network is disabled for this tutorial"
                    />
                  )}
                </div>
              </Section>

              <Section>
                <hr className="border-gray-200" />
              </Section>
            </>
          )}

          {request.sourcing === SourcingType.VENDORS && vendorSelectionType === 'disabled' ? (
            <>
              <div className="w-106">
                <NoAccess />
              </div>

              <PartHubProPitch />
            </>
          ) : (
            <Section className="space-y-6">
              <h4 className="text-xl font-medium text-gray-900">
                What location do you need the parts for?
              </h4>

              <LocationsList
                onLocationSelected={(point, locationId) =>
                  updateRequest({
                    nearbyReferencePoint: point,
                    locationId,
                  })
                }
                selectedLocationId={request.locationId ?? null}
                onAddLocationClicked={locationModalToggle.on}
                orgHasLocations={orgHasLocations}
              />
            </Section>
          )}
        </div>
      </Box>

      <StickyBottomBar>
        {reset && <StartOverButton reset={reset} />}

        <div className="flex-grow" />

        {onBackClicked && <Action.S onClick={onBackClicked}>Previous</Action.S>}

        <Action.P
          color="blue"
          onClick={() => onNext()}
          disabled={
            !request.locationId ||
            !isNumber(request.sourcing) ||
            (request.sourcing === SourcingType.VENDORS && vendorSelectionType === 'disabled')
          }
        >
          Next
        </Action.P>
      </StickyBottomBar>
    </>
  )
}

export default SourcingAndLocation
