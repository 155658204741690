import { useAllBranchesQuery, useSelectedDealerLocationsQuery } from '@/buyers/_gen/gql'
import LocationModal from '@/buyers/components/LocationModal'
import LocationSelector, {
  Queries as UserLocationsQueries,
} from '@/buyers/components/LocationSelector'
import { VendorBadgeType } from '@/buyers/components/Vendors/VendorBadge'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import Checkbox from '@/gf/components/next/forms/Checkbox'
import useToggle from '@/gf/hooks/useToggle'
import AddressM from '@/gf/modules/Address'
import { Maybe, Point } from '@/types'
import { CheckIcon, InformationCircleIcon, SearchIcon } from '@heroicons/react/outline'
import pluralize from 'pluralize'
import { useState } from 'react'
import { VendorSelectionType } from '../../types'
import SelectedVendorCard from './SelectedVendorCard'
import VendorCard from './VendorCard'
// import VendorSelectorPaywall from './VendorSelectorPaywall'
import BrandsFilter from '@/buyers/components/BrandsFilter'
import CompleteVendorModal from '@/buyers/components/Vendors/CompleteVendorModal'
import SearchInput from '@/gf/components/SearchInput'
import * as GE from '@/gf/modules/GrammarEvents'
import { StringParam, useQueryParam } from 'use-query-params'
import SearchLocationsModal from './SearchLocationsModal'
import SuggestedVendors from './SuggestedVendors'
import useVendorsQuery from './useVendorsQuery'

const FindVendorsCTA = ({ onClick }) => (
  <div className="p-6 space-y-4">
    <hgroup className="space-y-2 text-center">
      <h4 className="font-medium text-lg">Can’t find one of your vendors?</h4>
      <p className="text-base">Browse all active vendors or add a new vendor to Gearflow.</p>
    </hgroup>
    <div className="flex justify-center">
      <Action.S onClick={onClick}>
        <SearchIcon className="inline-block h-4 w-4" /> Find Vendor
      </Action.S>
    </div>
  </div>
)

const VendorsSelector = ({
  nearbyReferencePoint,
  locationId,
  machineBrand,
  selectedIds,
  onDealerLocationsChanged,
  onLocationChanged,
  vendorSelectionType,
  broadcast,
  onBroadcastChanged,
  excludeVendorIds,
  orgMachineId,
  disabledContactIds,
  tutorial = false,
}: {
  nearbyReferencePoint?: Point
  locationId: Maybe<string>
  machineBrand: Maybe<{ id: string; name: string }>
  selectedIds: string[]
  onDealerLocationsChanged: (newValue: string[]) => void
  onLocationChanged: (point?: Point, locationId?: string) => void
  vendorSelectionType: VendorSelectionType
  broadcast?: boolean
  onBroadcastChanged?: (broadcast: boolean) => void
  excludeVendorIds: string[]
  orgMachineId: string | null
  disabledContactIds: string[]
  tutorial?: boolean
}) => {
  const client = useGqlClient()
  const [searchLocationsModalOpen, searchLocationsModalToggle] = useToggle()
  const [addingLocation, setAddingLocation] = useState(false)
  const [completeVendorId, setCompleteVendorId] = useQueryParam('complete-vendor', StringParam)
  const { orgId, organization } = useSession()
  const branches = useAllBranchesQuery({ variables: { value: '' }, client }).data?.allBranches

  const listDrafts = vendorSelectionType === 'full'

  const {
    refetchDealerLocations,
    dealerLocations,
    loading,
    totalResults,
    filters,
    updateFilters,
    onSearchChanged,
  } = useVendorsQuery(
    nearbyReferencePoint ?? null,
    machineBrand?.id ?? null,
    vendorSelectionType,
    excludeVendorIds,
    disabledContactIds,
    listDrafts,
    tutorial
  )

  const { data: selectedData, previousData: selectedPrevData } = useSelectedDealerLocationsQuery({
    client,
    variables: {
      orgId,
      nearPoint: null,
      ids: selectedIds,
      tutorial,
    },
    skip: selectedIds.length === 0,
  })

  const selectedDealers = (selectedData ?? selectedPrevData)?.dealerLocations.entries ?? []

  const selectDealer = (selectedId: string) =>
    onDealerLocationsChanged([...selectedIds, selectedId])

  const removeDealer = (removedId: string) => {
    onDealerLocationsChanged(
      selectedIds.filter((dealerLocationId) => dealerLocationId !== removedId)
    )
    GE.whenCreateRequestPath(() => GE.removesDealerLocation(removedId))
  }

  const isSelected = (comparingDealerLocationId: string) =>
    !!selectedIds.find((dealerLocationId) => dealerLocationId === comparingDealerLocationId)

  const onVendorCreated = () => refetchDealerLocations()

  const onDraftVendorCompleted = (vendorId: string) => {
    refetchDealerLocations()

    const dl = dealerLocations.find(({ vendor }) => (vendor ? vendor.id === vendorId : false))
    if (dl) {
      selectDealer(dl.id)
    }

    GE.whenCreateRequestPath(() => GE.completesVendor(vendorId))
  }

  return (
    <>
      <SearchLocationsModal
        open={searchLocationsModalOpen}
        onClose={searchLocationsModalToggle.off}
        nearbyReferencePoint={nearbyReferencePoint}
        locationId={locationId}
        machineBrand={machineBrand}
        selectedIds={selectedIds}
        onDealerLocationsChanged={onDealerLocationsChanged}
        onLocationChanged={onLocationChanged}
        excludeVendorIds={excludeVendorIds}
        onVendorCreated={onVendorCreated}
        orgMachineId={orgMachineId}
      />

      {completeVendorId && (
        <CompleteVendorModal
          open
          onClose={() => setCompleteVendorId(undefined)}
          vendorId={completeVendorId}
          onSuccess={onDraftVendorCompleted}
        />
      )}

      <LocationModal
        open={addingLocation}
        refetchQueries={UserLocationsQueries}
        onClose={() => setAddingLocation(false)}
        onComplete={(newLocationId, variables) =>
          onLocationChanged(variables.address?.point as Point, newLocationId)
        }
        branches={(organization.requireBillingCompany ? branches : []) ?? []}
        buyers={[]}
        initialAddress={AddressM.init()}
        showPersist
      />

      <div className="flex gap-x-4 xl:gap-x-6">
        <Box className="p-6 shadow-base flex-grow min-w-0">
          <div className="w-full space-y-6">
            {nearbyReferencePoint && machineBrand && vendorSelectionType === 'full' && (
              <SuggestedVendors
                brandId={machineBrand?.id ?? null}
                excludeVendorIds={excludeVendorIds}
                selectedDealerLocationIds={selectedIds}
                point={nearbyReferencePoint}
                onAddDealer={(dlId) => selectDealer(dlId)}
                onRemoveDealer={(dlId) => removeDealer(dlId)}
              />
            )}

            <div className="space-y-3">
              <div className="flex flex-wrap gap-2">
                <LocationSelector
                  value={locationId}
                  onChange={(location) => {
                    onLocationChanged(location.address.point ?? undefined, location.id)
                    updateFilters({ location: location.address.point })
                  }}
                />

                <SearchInput
                  placeholder="Search for Vendor"
                  value={filters.search}
                  onChange={(value) => onSearchChanged(value)}
                  className="flex-grow"
                />
              </div>

              <div className="flex gap-x-4 items-center text-base">
                <BrandsFilter
                  value={filters.brands}
                  onChange={(brands) => updateFilters({ brands })}
                />
                {filters.brands.length > 0 && (
                  <Action.T onClick={() => updateFilters({ brands: [] })}>Clear makes</Action.T>
                )}
              </div>
            </div>

            <div className="relative">
              {/* this is no longer needed since the  */}
              {/* {vendorSelectionType === 'limited' && <VendorSelectorPaywall />} */}

              <p className="text-sm text-gray-700 flex justify-between pr-36 py-2 border-b border-gray-300">
                <span>
                  {totalResults} {pluralize('result', totalResults ?? 1)}
                </span>
                <span>Sorting by distance</span>
              </p>
              {loading ? (
                [1, 2, 3].map((i) => <VendorCard.Loading key={i} className="py-6" />)
              ) : dealerLocations.length === 0 ? (
                <div>
                  <p className="py-4">Sorry, no vendors were found for your filters.</p>

                  {vendorSelectionType === 'full' && (
                    <FindVendorsCTA onClick={searchLocationsModalToggle.on} />
                  )}
                </div>
              ) : (
                dealerLocations.map((dl, i) => (
                  <div
                    key={dl.id}
                    className="flex items-start gap-x-4 py-6 border-b border-gray-200"
                  >
                    <VendorCard
                      name={dl.name}
                      distance={dl.distance}
                      logoUrl={dl.logoUrl}
                      numberOfQuotes={dl.numOfQuotes}
                      responseTimeSec={dl.timeToQuoteSec}
                      badges={dl.vendor?.draft ? [VendorBadgeType.DraftVendor] : []}
                      address={{
                        city: dl.address?.city ?? dl.cityState,
                        state: dl.address?.city ? dl.address?.state : null,
                      }}
                      actionButton={
                        isSelected(dl.id) ? (
                          <Action.S
                            className="flex-shrink-0 w-9 h-9"
                            onClick={() => removeDealer(dl.id)}
                          >
                            <div className="flex flex-row justify-center items-center gap-x-1">
                              <CheckIcon className="flex flex-shrink-0 w-5 h-5" />
                            </div>
                          </Action.S>
                        ) : (
                          <Action.P
                            className="flex-shrink-0 w-[4.5rem] h-9"
                            color="blue"
                            onClick={() => {
                              if (!dl.vendor?.draft) {
                                GE.whenCreateRequestPath(() =>
                                  GE.selectsDealerLocation(dl.id, i, filters, !dl.vendor)
                                )
                                selectDealer(dl.id)
                              } else {
                                setCompleteVendorId(dl.vendor.id)
                              }
                            }}
                          >
                            Select
                          </Action.P>
                        )
                      }
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        </Box>

        <div className="flex-shrink-0 w-[21.85rem] space-y-4">
          <Box className="shadow-base p-6">
            <p className="text-sm xl:text-base text-gray-600 font-medium">
              {selectedIds.length} {pluralize('vendor', selectedIds.length)} added
            </p>

            {(selectedIds.length > 0 || broadcast !== undefined) && (
              <div className="space-y-3">
                <div>
                  {selectedDealers.map((dl) => (
                    <SelectedVendorCard
                      key={dl.id}
                      className="py-4 last:pb-0 border-b last:border-0 border-gray-200"
                      name={dl.name}
                      logoUrl={dl.logoUrl}
                      address={
                        dl.cityState
                          ? {
                              city: dl.cityState.split(', ')[0],
                              state: dl.cityState.split(', ')[1],
                            }
                          : dl.address
                            ? { city: dl.address.city, state: dl.address.state }
                            : null
                      }
                      onRemoveClick={() => removeDealer(dl.id)}
                    />
                  ))}
                </div>
                {broadcast !== undefined && (
                  <label className="flex items-center gap-x-3">
                    <Checkbox
                      checked={broadcast}
                      onChange={(e) => onBroadcastChanged && onBroadcastChanged(e.target.checked)}
                    />
                    <span className="flex-grow text-sm font-medium">
                      Include Gearflow Supplier Network
                    </span>
                    <Tooltip placement="bottom-end">
                      <TooltipTrigger>
                        <InformationCircleIcon className="h-5 w-5 text-blue-600" />
                      </TooltipTrigger>
                      <TooltipContent className="w-52 p-3 z-[99999] bg-gray-700 border border-gray-900 rounded shadow-sm text-sm text-gray-100">
                        Source parts from hundreds of suppliers in North America.
                      </TooltipContent>
                    </Tooltip>
                  </label>
                )}
              </div>
            )}
          </Box>

          {vendorSelectionType === 'full' && (
            <Box className="shadow-base bg-gray-50">
              <FindVendorsCTA onClick={searchLocationsModalToggle.on} />
            </Box>
          )}
        </div>
      </div>
    </>
  )
}

export default VendorsSelector
