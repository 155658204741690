import { RefObject } from 'react'
import request from './request.png'
import Link from '@/gf/components/Link'
import Action from '@/gf/components/Action'
import { useCreateTutorialVendorsMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import usePersistedState from '../CreateRequest/usePersistedState'
import useToggle from '@/gf/hooks/useToggle'

const CreateFirstRequest = ({
  containerRef,
  isProPlan,
}: {
  containerRef: RefObject<HTMLElement>
  isProPlan: boolean
}) => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const { update, emptyState } = usePersistedState('new-request-data')
  const [loading, loadingToggle] = useToggle()
  const [createVendors] = useCreateTutorialVendorsMutation({
    client,
    variables: { orgId },
  })
  const scrollToRequestSteps = () => {
    const requestStepsEl = document.getElementById('request-steps')
    if (!requestStepsEl) {
      return
    }

    containerRef.current?.scrollTo({
      top: requestStepsEl.getBoundingClientRect().y - 100,
    })
  }

  const startTutorial = async () => {
    try {
      loadingToggle.on()
      await createVendors()

      update({ ...emptyState, tutorial: true })

      // use this hard redirect to make sure the request page picks up the new state
      document.location.href = `/rfqs/create?source.path=${encodeURIComponent(
        document.location.pathname
      )}&source.button=how-it-works`
    } catch (err) {
      console.error(err)
    } finally {
      loadingToggle.off()
    }
  }

  return (
    <section className="border border-gray-200 shadow-base rounded-xl bg-gray-50 h-auto lg:h-[26.75rem] p-4 lg:p-0 flex flex-col lg:flex-row items-center overflow-hidden">
      <div className="space-y-4 max-w-full lg:max-w-[25.5rem] lg:pl-12">
        <h2 className="text-3xl font-medium">Get started on Gearflow</h2>
        <p className="text-lg">
          Get started by adding a local vendor and sending them a request for parts.
        </p>
        <div className="flex flex-col gap-y-2 items-start pt-4 sm:pt-16">
          <Action.S
            onClick={() => (isProPlan ? startTutorial() : scrollToRequestSteps())}
            size="lg"
            className="font-medium"
            performing={loading}
            disabled={loading}
          >
            See how it works
          </Action.S>

          <Link.P
            to={`/rfqs/create?source.path=${encodeURIComponent(
              document.location.pathname
            )}&source.button=create-request-top`}
            size="lg"
            className="font-medium"
            color="blue"
          >
            Get parts
          </Link.P>
        </div>
      </div>
      <div className="relative flex-grow h-full pt-6 lg:pt-0 lg:pl-7">
        <img src={request} alt="Request flow" className="w-[35rem] top-0 lg:top-12 lg:absolute" />
      </div>
    </section>
  )
}

export default CreateFirstRequest
