import { useUsersQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'

import type { PlanSubscription } from '@/buyers/hooks/usePlanQuery'

import MoneyM from '@/gf/modules/Money'
import Time from '@/gf/modules/Time'

import Card from '@/gf/components/Card'

const Box = ({
  title,
  children,
}: {
  title: React.ReactNode | string
  children: React.ReactNode
}) => (
  <div className="flex flex-col items-center gap-2 pl-8 py-2 first:pl-0">
    <div className="text-gray-500 text-sm font-bold whitespace-nowrap">{title}</div>
    {children}
  </div>
)

const Plan = ({ planSubscription }: { planSubscription: PlanSubscription }) => {
  const { organization } = useSession()
  const orgId = organization.id
  const usersResult = useUsersQuery({ variables: { orgId }, client: useGqlClient() })

  if (!usersResult.data?.org) return null

  const basePrice = planSubscription?.plan?.basePrice
    ? planSubscription.plan.basePrice
    : MoneyM.fromInt(0, 'USD')

  const total =
    planSubscription.plan.subscriptionType === 'php_fixed'
      ? planSubscription.plan.amount
      : planSubscription.plan.subscriptionType === 'php_fixed_plus'
        ? MoneyM.add(
            MoneyM.mult(planSubscription.plan.amount, planSubscription.quantity),
            basePrice
          )
        : MoneyM.mult(planSubscription.plan.amount, planSubscription.quantity)

  const nextInvoice = Time.toFormat(planSubscription.currentPeriodEnd, 'MMM d, yyyy')
  const usedLicenses = usersResult.data.org.users.filter((u) => u.active).length
  const licenses = planSubscription.quantity + organization.freeLicenses

  return (
    <Card title="Parts Hub Pro">
      <Card.Section className="pt-2 pb-4">
        <div className="space-y-6">
          <div className="flex gap-8 divide-x">
            <Box
              title={
                <div className="flex gap-2 items-center">
                  <span>Licenses</span>
                </div>
              }
            >
              <div
                className={`text-xl whitespace-nowrap ${
                  usedLicenses > licenses ? 'text-red-600' : ''
                }`}
              >
                {usedLicenses} / {licenses}
              </div>
            </Box>
            <Box
              title={
                <>
                  <span className="capitalize">{planSubscription.plan.frequency}</span> Total
                </>
              }
            >
              <div className="text-xl">{MoneyM.format(total)}</div>
            </Box>
            <Box title="Next Invoice">
              <div className="text-xl">{nextInvoice}</div>
            </Box>
          </div>
        </div>
      </Card.Section>
    </Card>
  )
}

export default Plan
