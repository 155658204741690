import {
  OrgMachineDetailsQuery,
  OrganizationDetailsQuery,
  SelectedBranchQuery,
  SelectedLocationQuery,
  useSelectedVendorQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import VendorRequestEmail from '@/gf/components/VendorRequestEmail'
import useToggle from '@/gf/hooks/useToggle'
import * as GE from '@/gf/modules/GrammarEvents'
import { Maybe, ModalSize } from '@/types'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { CreateRequestState } from '../types'

const PreviewEmailButton = ({
  request,
  location,
  orgMachine,
  organization,
  branch,
}: {
  request: CreateRequestState
  location: SelectedLocationQuery['shippingLocationsSearch']['shippingLocations'][number]
  orgMachine: OrgMachineDetailsQuery['orgMachine']
  organization: NonNullable<OrganizationDetailsQuery['org']>
  branch: Maybe<SelectedBranchQuery['branch']>
}) => {
  const client = useGqlClient()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [open, toggle] = useToggle()
  const { user } = useSession()

  const { data: vendorData } = useSelectedVendorQuery({
    client,
    variables: {
      id: request.vendors[currentIndex]?.vendorId,
    },
    skip: request.vendors.length === 0,
  })

  const vendor = vendorData?.vendor ?? null
  const vendorContacts = useMemo(() => {
    if (!vendor) {
      return []
    }

    const selectedContacts = request.vendors.find((v) => v.vendorId === vendor.id)?.contactIds ?? []
    return vendor?.contacts.filter((c) => selectedContacts.includes(c.id)) ?? []
  }, [vendor, request.vendors])

  return (
    <>
      <Action.S
        onClick={() => {
          toggle.on()
          GE.whenCreateRequestPath(() =>
            GE.clicksButtonOnFlow('preview-email', GE.UserFlow.CreateRequest)
          )
        }}
      >
        Preview Email
      </Action.S>
      <Modal size={ModalSize.LG} open={open} onClose={toggle.off}>
        <div className="flex flex-col gap-y-3 px-4 py-6">
          <div className="flex justify-end">
            <CloseModalButton onClick={toggle.off} />
          </div>
          <div>
            <h6 className="leading-6 font-medium mb-1">Quote Request from {organization.name}</h6>
            <p className="leading-5 text-gray-600 text-sm">
              From: Gearflow & {'<orders@gearflow.com>'}
              <br />
              To: {vendor?.name} {`<${vendorContacts.map((c) => c.email).join(', ')}>`} <br />
              Reply-To: orders@gearflow.com
            </p>
          </div>

          <VendorRequestEmail.Wrapper className="max-h-[70vh] overflow-y-auto">
            <VendorRequestEmail
              readonly
              showEmailCopy
              customerName={user.name ?? organization.name}
              customerPhoneNumber={user.phoneNumber || ''}
              customerEmail={user.email}
              organization={{
                ...organization,
                billingAddress: organization.billingAddress
                  ? organization.billingAddress
                  : undefined,
              }}
              branch={branch}
              vendor={vendor}
              vendorContacts={vendorContacts}
              machineDown={request.urgency?.machineDown ?? null}
              requestTimestamp={DateTime.now()}
              neededBy={request.urgency?.neededByDate ?? null}
              shippingAddress={location?.address ?? null}
              deliveryMethod={request.vendors[currentIndex]?.deliveryMethod ?? null}
              accountNumber={request.vendors[currentIndex]?.accountNumber ?? null}
              machine={
                orgMachine
                  ? {
                      name: orgMachine.name,
                      serialNumber: orgMachine.serialNumber,
                      make: orgMachine.machine.make,
                      model: orgMachine.machine.model,
                      year: orgMachine.machine.year,
                    }
                  : null
              }
              partRequests={
                request.parts?.map((p) => ({
                  mpn: p.partNumber ?? '',
                  description: p.description ?? '',
                  quantity: p.quantity,
                })) ?? []
              }
              additionalDetails={request.comments ?? ''}
              imageUrls={request.parts?.map((p) => p.pictures).flat() ?? []}
              organizationLogoUrl={organization.logoUrl}
            />
          </VendorRequestEmail.Wrapper>

          {request.vendors.length > 1 && (
            <div className="flex gap-x-4 justify-end">
              <Action.S
                onClick={() => setCurrentIndex(currentIndex - 1)}
                disabled={currentIndex === 0}
                className="w-24"
              >
                Previous
              </Action.S>
              <Action.S
                onClick={() => setCurrentIndex(currentIndex + 1)}
                disabled={currentIndex === request.vendors.length - 1}
                className="w-24"
              >
                Next
              </Action.S>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default PreviewEmailButton
