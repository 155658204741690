import useGqlClient from '@/buyers/hooks/useGqlClient'
import { useEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { usePendingInvitesQuery } from '../_gen/gql'
import useSession from './useSession'

interface AddUserForm {
  fields: {
    inviteId: string | null
    name: string
    email: string
    phoneNumber: string
    title: string
    roleId: string | null
  }
  errors: {
    name: string | null
    email: string | null
    phoneNumber: string | null
    title: string | null
    roleId: string | null
  }
}

// If the AddUserForm type changes, bump this version
const key = 'add-user-form-v2'

const sessionStore = {
  fetch: (inviteId: string | null) => {
    const item = sessionStorage.getItem(inviteId ? `${key}/${inviteId}` : key)
    if (!item) return null

    return JSON.parse(item) as AddUserForm
  },
  save: (form: AddUserForm) =>
    sessionStorage.setItem(
      form.fields.inviteId ? `${key}/${form.fields.inviteId}` : key,
      JSON.stringify(form)
    ),
}

const useAddUserForm = (defaultRoleId?: string) => {
  const { orgId } = useSession()
  const inviteId = useMatch('/settings/users/invites/:inviteId/*')?.params.inviteId || null

  const invite = usePendingInvitesQuery({
    variables: { orgId },
    client: useGqlClient(),
    skip: !inviteId,
  }).data?.org?.pendingInvites.find((pi) => pi.id === inviteId)

  const initForm: AddUserForm = {
    fields: {
      inviteId,
      name: '',
      email: '',
      phoneNumber: '',
      title: '',
      roleId: defaultRoleId ?? null,
    },
    errors: { name: null, email: null, phoneNumber: null, title: null, roleId: null },
  }

  const [form, setForm] = useState(sessionStore.fetch(inviteId) || initForm)

  const save = (updatedForm: AddUserForm) => {
    sessionStore.save(updatedForm)
    setForm(updatedForm)
  }

  const updateFields = (fields: Partial<AddUserForm['fields']>) =>
    save({ ...form, fields: { ...form.fields, ...fields } })

  useEffect(() => {
    if (invite) {
      const updates: Partial<AddUserForm['fields']> = { email: invite.user.email }
      if (form.fields.name === '') updates.name = invite.user.name || ''
      if (form.fields.email === '') updates.email = invite.user.email
      if (form.fields.phoneNumber === '') updates.phoneNumber = invite.user.phoneNumber || ''
      if (!form.fields.roleId) updates.roleId = invite.user.userRole?.id
      updateFields(updates)
    }
  }, [!invite])

  const reset = () => save(initForm)

  const resetErrors = () => save({ ...form, errors: initForm.errors })

  const validate = (context?: 'details' | 'permissions') => {
    resetErrors()

    return new Promise<void>((resolve, reject) => {
      let { errors } = initForm
      const { fields } = form

      if (!context || context === 'details') {
        if (fields.name.length === 0) errors = { ...errors, name: 'Name is required.' }
        if (fields.email.length === 0) errors = { ...errors, email: 'Email is required.' }

        if (fields.phoneNumber.length === 0)
          errors = { ...errors, phoneNumber: 'Phone number is required.' }
      }

      if (!context || context === 'permissions')
        if (!fields.roleId) errors = { ...errors, roleId: 'Must select a role.' }

      save({ ...form, errors: { ...form.errors, ...errors } })

      if (errors === initForm.errors) resolve()
      else reject()
    })
  }

  return { form, updateFields, reset, resetErrors, validate }
}

export type AdminUserFormReducer = ReturnType<typeof useAddUserForm>

export default useAddUserForm
