import {
  RequestsQuery,
  SortByInput,
  SortOrder,
  useRequestCountsQuery,
  useRequestsQuery,
} from '@/buyers/_gen/gql'
import Frame from '@/buyers/components/Frame'
import RfqStepBadge from '@/buyers/components/RfqStepBadge'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Dropdown from '@/gf/components/Dropdown'
import FilterAction from '@/gf/components/FilterAction'
import FilterProgressNav from '@/gf/components/FilterProgressNav'
import Link from '@/gf/components/Link'
import MachineDownIcon from '@/gf/components/MachineDownIcon'
import NeededByPill from '@/gf/components/NeededByPill'
import Page from '@/gf/components/Page'
import PaginationC from '@/gf/components/Pagination'
import SortByHeaderButton from '@/gf/components/SortByHeaderButton'
import Spinner from '@/gf/components/Spinner'
import { TableV2 as Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import TimeOpenPill from '@/gf/components/TimeOpenPill'
import DelayedSearchInput from '@/gf/components/inputs/DelayedSearch'
import usePage from '@/gf/hooks/usePage'
import useWindowWidth from '@/gf/hooks/useWindowWidth'
import RequestForQuoteM from '@/gf/modules/RequestForQuote'
import {
  CheckCircleIcon,
  ClipboardCheckIcon,
  InboxIcon,
  PaperAirplaneIcon,
  ReplyIcon,
  TruckIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { DateTime } from 'luxon'
import { useEffect, useRef, useState } from 'react'
import { BooleanParam, JsonParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import AssignUserDropdown from '../components/AssignUserDropdown'
import ActionCells from './Requests/ActionCells'
import Actions from './Requests/Actions'
import PartsCell from './Requests/PartsCell'
import Stores from './Requests/Stores'
import Vendors from './Requests/Vendors'

export type Request = RequestsQuery['searchRequests']['requests'][number]

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [{ name: 'Requests', href: '/rfqs' }],
}

const allSteps = [
  { id: 'all', name: 'All', icon: InboxIcon },
  { id: 'inbound', name: 'Inbound', icon: InboxIcon },
  { id: 'submitted', name: 'Submitted', icon: PaperAirplaneIcon },
  { id: 'quoted', name: 'Quoted', icon: ReplyIcon },
  { id: 'po_sent', name: 'PO Sent', icon: ClipboardCheckIcon },
  { id: 'fulfilling', name: 'Fulfilling', icon: TruckIcon },
  { id: 'fulfilled', name: 'Fulfilled', icon: CheckCircleIcon },
]

const now = DateTime.now()

const useQueryParamsOpts = {
  selected: StringParam,
  search: StringParam,
  sortBy: withDefault<SortByInput, SortByInput>(JsonParam, {
    field: 'needed_by',
    order: SortOrder.Desc,
  }),
  highPriority: withDefault(BooleanParam, false),
  backordered: withDefault(BooleanParam, false),
  myRequests: withDefault(BooleanParam, false),
  unassigned: withDefault(BooleanParam, false),
  'has-problems': withDefault(BooleanParam, false),
}

const Requests = () => {
  const {
    orgId,
    user: { id: userId },
    organization,
  } = useSession()

  const client = useGqlClient()
  const [page, setPage] = usePage()
  const [showActionDivider, setShowActionDivider] = useState<boolean>()
  const tableRef = useRef<HTMLDivElement>(null)
  const windowWidth = useWindowWidth(300)
  const [query, updateQuery] = useQueryParams(useQueryParamsOpts)

  const apiFilters = (stepId: string) => {
    const filters: (string | string[])[][] = []
    if (stepId !== 'all') filters.push(['step_eq', stepId])
    if (query.search && query.search.length > 0) filters.push(['search', query.search])
    if (query.highPriority) filters.push(['is_high_priority'])
    if (query.backordered) filters.push(['is_backordered'])
    if (query.myRequests) filters.push(['is_assigned_to', userId])
    if (query.unassigned) filters.push(['is_unassigned'])
    if (query['has-problems']) filters.push(['has_problems'])
    const filter = filters.length > 1 ? ['and', ...filters] : filters.length === 1 ? filters[0] : []
    return JSON.stringify(filter)
  }

  const { data: countsData } = useRequestCountsQuery({
    client,
    variables: {
      orgId,
      rfqCountFilter: apiFilters('all'),
      inboundRfqCountFilter: apiFilters('inbound'),
      submittedRfqCountFilter: apiFilters('submitted'),
      quotedRfqCountFilter: apiFilters('quoted'),
      poSentRfqCountFilter: apiFilters('po_sent'),
      fulfillingRfqCountFilter: apiFilters('fulfilling'),
    },
  })

  const showInbound = organization.requestApproval || (countsData?.org?.inboundRfqCount || 0) > 0

  const theSteps = allSteps.map((step) => {
    const org = countsData?.org

    const countByStepId: Record<string, number | undefined> = {
      all: org?.rfqCount,
      inbound: org?.inboundRfqCount,
      submitted: org?.submitteddRfqCount,
      quoted: org?.quotedRfqCount,
      po_sent: org?.poSentRfqCount,
      fulfilling: org?.fulfillingRfqCount,
    }

    return {
      ...step,
      count: countByStepId[step.id],
      hidden: step.id === 'inbound' && !showInbound,
    }
  })

  const steps = theSteps.filter((step) => !step.hidden)
  const selectedStep = query.selected === 'inbound' && !showInbound ? 'all' : query.selected
  const step = theSteps.filter((s) => s.id === (selectedStep || 'all'))[0]

  const sortBy = (() => {
    if (query.sortBy.field === 'vendors') {
      const field = step.id === 'quoted' ? 'first_store_order_store' : 'first_vendor'
      return { ...query.sortBy, field }
    }

    return query.sortBy
  })()

  const queryResult = useRequestsQuery({
    variables: { orgId, userId, page, filter: apiFilters(step.id), sortBy },
    client: useGqlClient(),
    fetchPolicy: 'no-cache',
  })

  const { searchRequests, user } = queryResult.data || {}

  const handleShowActionDivider = (
    scrollLeft: number,
    scrollWidth: number,
    clientWidth: number
  ) => {
    const threshold = 5
    const isScrollAtEnd = scrollLeft + threshold >= scrollWidth - clientWidth
    if (typeof showActionDivider === 'undefined') setShowActionDivider(!isScrollAtEnd)
    else if (isScrollAtEnd && showActionDivider) setShowActionDivider(false)
    else if (!isScrollAtEnd && !showActionDivider) setShowActionDivider(true)
  }

  // Update the action divider when the window resizes or the action divider is undefined
  useEffect(() => {
    if (tableRef.current && searchRequests) {
      handleShowActionDivider(
        tableRef.current.scrollLeft,
        tableRef.current.scrollWidth,
        tableRef.current.clientWidth
      )
    }
  }, [windowWidth, typeof handleShowActionDivider === 'undefined', tableRef, searchRequests])

  const setStep = (index: number) => {
    setPage(undefined)
    setShowActionDivider(undefined)
    const selected = index === 0 ? undefined : steps[index].id
    updateQuery({ selected })
  }

  const setSortBy = (newSortBy: SortByInput) => updateQuery({ sortBy: newSortBy })

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page
        title="Requests"
        actionsNext={[
          <Link.P
            to={`/rfqs/create?source.path=${encodeURIComponent(
              document.location.pathname
            )}&source.button=create-request`}
            key="create-request"
          >
            Create Request
          </Link.P>,
        ]}
        className="flex flex-col overflow-auto gap-4"
      >
        <FilterProgressNav
          steps={steps}
          selectedIndex={steps.indexOf(step)}
          setSelectedIndex={setStep}
          isFirstStepAnIsland
          textColorClassName="text-orange-500"
          bgColorClassName="bg-orange-500"
        />

        <DelayedSearchInput
          value={query.search}
          setValue={(search) => updateQuery({ search: search.length > 0 ? search : undefined })}
          placeholder="Search requests&hellip;"
        />

        <div className="flex flex-wrap gap-2 items-center">
          <div className="text-sm text-gray-500">Filter by:</div>

          <FilterAction
            selected={query.highPriority}
            onSelect={() => updateQuery({ highPriority: query.highPriority ? undefined : true })}
          >
            High Priority
          </FilterAction>

          <FilterAction
            selected={query.backordered}
            onSelect={() => updateQuery({ backordered: query.backordered ? undefined : true })}
          >
            Backordered
          </FilterAction>

          <FilterAction
            selected={query.myRequests}
            onSelect={() => updateQuery({ myRequests: query.myRequests ? undefined : true })}
          >
            My Requests
          </FilterAction>

          <FilterAction
            selected={query.unassigned}
            onSelect={() => updateQuery({ unassigned: query.unassigned ? undefined : true })}
          >
            Unassigned
          </FilterAction>

          <FilterAction
            selected={query['has-problems']}
            onSelect={() =>
              updateQuery({ 'has-problems': query['has-problems'] ? undefined : true })
            }
          >
            Has Problems
          </FilterAction>
        </div>

        <div className="shadow-sm border rounded-md flex flex-col overflow-visible xl:overflow-auto">
          <div
            className="overflow-auto"
            ref={tableRef}
            onScroll={(e) =>
              handleShowActionDivider(
                e.currentTarget.scrollLeft,
                e.currentTarget.scrollWidth,
                e.currentTarget.clientWidth
              )
            }
          >
            <Table className="w-full">
              <Thead>
                <Tr>
                  <Th>
                    <SortByHeaderButton
                      field="needed_by"
                      display="Needed By"
                      sortBy={query.sortBy}
                      setSortBy={setSortBy}
                    />
                  </Th>
                  {step.id === 'closed' && <Th>Fulfillment</Th>}
                  <Th>
                    <SortByHeaderButton
                      field="inserted_at"
                      display="Created"
                      sortBy={query.sortBy}
                      setSortBy={setSortBy}
                    />
                  </Th>
                  <Th>Request</Th>
                  {['all', 'inbound'].includes(step.id) && <Th>Assigned To</Th>}

                  {step.id !== 'inbound' && (
                    <Th>
                      <SortByHeaderButton
                        field="vendors"
                        display="Vendors"
                        sortBy={query.sortBy}
                        setSortBy={setSortBy}
                      />
                    </Th>
                  )}

                  {step.id === 'all' && <Th>Status</Th>}
                  <Th>Parts</Th>
                  <Th>Machine</Th>
                  <Th className="hidden sm:table-cell w-0 sticky right-0 z-10 bg-gray-50 bg-opacity-80" />
                  <Th className="table-cell sm:hidden" />
                </Tr>
              </Thead>
              <Tbody>
                {user &&
                  searchRequests?.requests.map((rfq, index) => (
                    <Tr key={rfq.id}>
                      <Td>
                        <NeededByPill rfq={rfq} />
                      </Td>

                      {step.id === 'closed' && (
                        <Td>
                          <TimeOpenPill
                            className={rfq.machineDown || rfq.neededBy ? 'px-0' : undefined}
                            createdAt={rfq.insertedAt}
                            closedAt={rfq.timeline.fulfilledAt || rfq.canceledAt || rfq.closedAt}
                          />
                        </Td>
                      )}

                      <Td>
                        <div className="space-y-1">
                          <div>
                            {rfq.insertedAt.year < now.year
                              ? rfq.insertedAt.toLocal().toFormat('D - h:mm a')
                              : rfq.insertedAt.toLocal().toFormat('M/d - h:mm a')}
                          </div>

                          {rfq.creator && (
                            <div className="text-xs text-slate-400 italic">
                              <div>{rfq.creator.name}</div>
                            </div>
                          )}
                        </div>
                      </Td>

                      <Td>
                        <Link.T to={`/rfqs/${rfq.id}`} className="text-base">
                          {RequestForQuoteM.shortenId(rfq.id)}
                        </Link.T>
                        {!['all', 'inbound'].includes(step.id) && rfq.assignedUser && (
                          <div className="text-xs text-slate-400 italic pt-1">
                            {rfq.assignedUser.name}
                          </div>
                        )}
                      </Td>

                      {['all', 'inbound'].includes(step.id) && (
                        <Td className="p-0">
                          <AssignUserDropdown
                            rfq={rfq}
                            onUserAssigned={queryResult.refetch}
                            menuClassName="mt-2 ml-4"
                            trigger={
                              <span className="truncate max-w-72 hover:bg-blue-100 px-6 py-4">
                                {rfq.assignedUser?.name ?? (
                                  <span className="italic text-gray-500">Unnassigned</span>
                                )}{' '}
                                <ChevronDownIcon className="w-5 h-5 inline-block" />
                              </span>
                            }
                          />
                        </Td>
                      )}

                      {step.id !== 'inbound' ? (
                        step.id === 'quoted' ? (
                          <Td className="p-0">
                            {' '}
                            <Stores stores={rfq.storeOrders.map((so) => so.store)} />
                          </Td>
                        ) : (
                          <Td className="p-0">
                            <Vendors rfq={rfq} />
                          </Td>
                        )
                      ) : null}

                      {step.id === 'all' && (
                        <Td>
                          <RfqStepBadge step={rfq.step} />
                        </Td>
                      )}

                      <PartsCell rfq={rfq} />

                      <Td className="p-0">
                        <div className="text-left block divide-x-1 divide-dotted">
                          {rfq.orgMachines.map((om) => (
                            <Dropdown
                              className="text-left"
                              title={om.name}
                              titleElement={
                                <>
                                  <div className="flex flex-row items-center gap-x-1">
                                    {rfq.machineDown && <MachineDownIcon />}
                                    <p>{om.name}</p>
                                  </div>
                                  <p className="text-xs text-slate-400 italic mt-1 text-left">
                                    {om.machine.make} {om.machine.model}
                                  </p>
                                </>
                              }
                              chevronClassName={rfq.machineDown ? 'mt-1' : undefined}
                              stopEventPropagation
                              key={om.id}
                            >
                              <div className="grid grid-cols-3 auto-cols-auto w-80 whitespace-normal text-sm">
                                <span>Serial #</span>
                                <span className="col-span-2">{om.serialNumber}</span>
                                <span>Make</span>
                                <span className="col-span-2">{om.machine.make}</span>
                                <span>Model</span>
                                <span className="col-span-2">{om.machine.model}</span>
                                <span>Year</span>
                                <span className="col-span-2">{om.machine.year}</span>
                              </div>
                            </Dropdown>
                          ))}
                        </div>
                      </Td>

                      <ActionCells
                        showActionDivider={showActionDivider}
                        firstOrLast={index === 0 || index === searchRequests.requests.length - 1}
                      >
                        <Actions user={user} rfq={rfq} />
                      </ActionCells>
                    </Tr>
                  ))}

                {!searchRequests && (
                  <Tr>
                    <Td colSpan={100}>
                      <Spinner />
                    </Td>
                  </Tr>
                )}

                {searchRequests?.requests.length === 0 && (
                  <Tr>
                    <Td colSpan={100}>
                      No{selectedStep !== 'all' && <> {selectedStep}</>} requests found.
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </div>
        </div>

        <PaginationC pagination={searchRequests?.pagination} page={page} updatePage={setPage} />
      </Page>
    </Frame>
  )
}

export default Requests
