import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import Box from '@/gf/components/Box'
import PaginationC from '@/gf/components/Pagination'
import ReportingTable, { NoResults } from '@/gf/components/Reports/ReportingTable'
import Time from '@/gf/modules/Time'
import {
  PeriodicPartRequestsQuery,
  SortByInput,
  SortOrder,
  usePeriodicPartRequestsQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'

import usePage from '@/gf/hooks/usePage'
import SearchInput from '@/gf/components/SearchInput'
import { useRef, useState } from 'react'
import { DateTime } from 'luxon'
import PartStoreOrders from './Consumption/PartStoreOrders'
import RequestPartButton from '@/buyers/components/RequestPartButton'

const getRequestPartButton = ({
  partNumber,
  partDescription,
  requestQty,
}: PeriodicPartRequestsQuery['periodicPartRequests']['entries'][number]) => (
  <RequestPartButton
    partNumber={partNumber}
    partDescription={partDescription}
    requestQty={requestQty}
  />
)

const Consumption = () => {
  const client = useGqlClient()
  const { orgId } = useSession()
  const [page, setPage] = usePage()
  const [searchTerm, setSearchTerm] = useQueryParam('search', withDefault(StringParam, ''))
  const [selectedId, setSelectedId] = useQueryParam('selected', StringParam)
  const [sortBy, setSortBy] = useState<SortByInput>({ field: 'default', order: SortOrder.Asc })
  const { data, previousData } = usePeriodicPartRequestsQuery({
    client,
    variables: {
      orgId,
      page,
      search: searchTerm,
      sortBy: sortBy.field !== 'default' ? sortBy : null,
      predictedOnly: true,
    },
  })

  const detailsRef = useRef<HTMLDivElement>(null)

  const onSearchChanged = (newValue: string) => {
    setSearchTerm(newValue, 'replaceIn')
    setPage(1)
  }

  const clearFilters = () => {
    setSearchTerm(undefined, 'replaceIn')
    setPage(1)
  }

  const periodicPartsData = data?.periodicPartRequests || previousData?.periodicPartRequests

  return (
    <>
      <h2 className="text-2xl text-gray-900 font-medium">Parts Consumption</h2>
      <Box className="p-4 rounded-xl">
        <div>
          <div className="pb-2">
            <SearchInput
              value={searchTerm}
              onChange={onSearchChanged}
              placeholder="Search by part number or part name"
              autoFocus
              autoCompleteOff
            />
          </div>

          <ReportingTable
            data={periodicPartsData?.entries}
            sortBy={{ sortBy, setSortBy }}
            getRowKey={({ id }) => id}
            noResults={<NoResults onClearFilters={clearFilters} />}
            checkbox={{
              getChecked: (row) => row.id === selectedId,
              onToggleRow: (row) => {
                setSelectedId(row.id, 'replaceIn')

                detailsRef.current?.scrollIntoView({ block: 'nearest' })
              },
              onClear: () => setSelectedId(undefined, 'replaceIn'),
            }}
            columns={[
              { header: 'Part Number', getValue: (row) => row.partNumber },
              {
                header: 'Part Name',
                getValue: (row) => row.partDescription,
                sortByField: 'part_description',
              },
              {
                header: 'Number of Orders',
                getValue: (row) => row.numOfOrders,
                sortByField: 'num_of_orders',
              },
              {
                header: 'Frequency',
                getValue: (row) =>
                  row.requestFrequencySec
                    ? `${row.requestQty} every ${Time.secondsToString2(row.requestFrequencySec)}`
                    : '',
                sortByField: 'request_frequency',
              },
              {
                header: 'Needed',
                getValue: (row) =>
                  row.neededBy
                    ? `${row.neededBy.toRelative()} (${row.neededBy.toLocaleString(
                        DateTime.DATE_SHORT
                      )})`
                    : '',
                sortByField: 'needed_by',
              },
              {
                header: '#',
                getValue: getRequestPartButton,
              },
            ]}
          />
          <hr />

          <div className="pt-4">
            <PaginationC
              pagination={periodicPartsData?.pagination}
              page={page}
              updatePage={setPage}
            />
          </div>
        </div>
      </Box>
      <div ref={detailsRef} className="min-h-[15rem]">
        {selectedId && <PartStoreOrders periodicPartId={selectedId} />}
      </div>
    </>
  )
}

export default Consumption
