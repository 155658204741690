import { HomeNextQuery } from '@/buyers/_gen/gql'
import useSession from '@/buyers/hooks/useSession'
import Ghost from '@/gf/components/Ghost'
import { useMemo } from 'react'

const WelcomeMessage = ({ org }: { org: HomeNextQuery['org'] }) => {
  const { user } = useSession()
  const name = useMemo(() => (user.name ? user.name.split(' ')[0] : null), [user.name])

  if (!org) {
    return <Ghost className="w-4/5 h-12 block" />
  }

  return (
    <h2 className="text-xl lg:text-2xl xl:text-3xl font-medium">
      {!org?.setupStartedAt
        ? `Hi ${name}! Let’s begin by setting up your workspace.`
        : org?.setupCompletedAt
          ? `Hi ${name}! Let’s keep building your account.`
          : `Hi ${name}! Let’s finish your initial setup.`}
    </h2>
  )
}

export default WelcomeMessage
